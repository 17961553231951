import { AxiosResponse } from "axios";
import { axiosInstance } from "src/services/axiosSetup";
import * as API_URL from "src/services/consts/Wishlist"
import { IAddToWishlistApiRequestBody } from "./module";

export function addToWishlistRequestApi(data: IAddToWishlistApiRequestBody): Promise<any> {
    return axiosInstance.post(`${API_URL.URL_ADD_TO_WISHLIST}`, data).then((d: AxiosResponse) => d.data);
}

export function getWishlistApiRequest(id: string): Promise<AxiosResponse<any>> {
    return axiosInstance.get(`${API_URL.URL_GET_ALL_WISHLIST}/${id}`).then((d: AxiosResponse) => d.data)
}
