import { CheckCheckIcon } from 'lucide-react'
import React, { useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { catchAsync, checkDecimal, IMG_EPC_COMPANY_LOGO, IS_SUCCESS_RESPONSE, USER_ROLES } from 'src/helpers'
import { INITIAL_SHIPPING_ORDER_FORM_VALUES, IProduct } from 'src/modules/Orders/modules'
import PaymentForm from 'src/modules/Orders/PaymentForm'
import ProductItem from 'src/modules/Orders/ProductItem'
import ShippingAddressForm from 'src/modules/Orders/ShippingAddressForm'
import { getOrderReducerRootState, setOrderDetails } from 'src/redux/reducers/orderReducer'
import { RootState } from 'src/redux/store'
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from 'src/shadcn/components/ui'
import { ScrollArea } from 'src/shadcn/components/ui/scroll-area'
import { IoIosCheckmarkCircle } from "react-icons/io";
import { useDispatch } from 'react-redux'
import { createOrderApiRequest } from 'src/services/requests/Order'
import { toast } from 'sonner'
import LoadingDialog from 'src/shadcn/components/custom/LoadingDialog'
import { useAuth } from 'src/modules/Auth/Core'
import { getCartReducerRootState } from 'src/redux/reducers/cartReducer'

type Props = {

}

const CreateOrderPage = (props: Props) => {


    const { createOrder: { orderItems, orderDetails }, orders: { orders } } = useSelector((state: RootState) => getOrderReducerRootState(state))
    const { cartItems } = useSelector((state: RootState) => getCartReducerRootState(state))
    const newCartItems = useMemo(() => cartItems.length > 0 ? cartItems.map((cartitem: any) => ({ ...cartitem.product, quantity: cartitem.quantity }),) : [], [cartItems])
    const dispatch = useDispatch()
    const totalPrice = useMemo(() => {
        let TotalCurrentPrice = orderItems.length > 0 ? orderItems.reduce((acc, product: any) => acc + (product.price * product.quantity), 0) : 0
        let TotalCartItemsPrice = newCartItems.length > 0 ? newCartItems.reduce((acc, product: any) => acc + (product.price * product.quantity), 0) : 0
        return TotalCurrentPrice + TotalCartItemsPrice
    }, [orderItems, cartItems])
    const [activeTab, setactiveTab] = useState("shipping_address")
    const [isLoading, setisLoading] = useState<boolean>(false)
    const { currentUser } = useAuth()


    const handleCreateOrder = catchAsync(async () => {
        setisLoading(true)
        return  await createOrderApiRequest({
            products: [...orderItems, ...newCartItems].map((d: any) => ({ product_id: d._id, quantity: d.quantity })),
            ...orderDetails,
            user_id: currentUser?.id,
            totalCost: totalPrice,

        })
    }, (result: any) => {
        if (IS_SUCCESS_RESPONSE(result)) {
            toast.success("Order create Successfully")
        }
        setisLoading(false)
    }, () => { setisLoading(false) })

    return (
        <div className='p-4' >
            <div className='text-primary text-lg font-semibold uppercase my-4'>Order Details</div>
            <div className='grid grid-cols-5 gap-4'>
                <div className='col-span-3 bg-gray-50 space-y-4 p-4'>
                    <Accordion type="single" collapsible value={activeTab} >
                        <AccordionItem value="shipping_address" onClick={() => setactiveTab("shipping_address")}>
                            <AccordionTrigger>
                                <div className='flex items-center space-x-2'>
                                    <span><IoIosCheckmarkCircle size={24} /></span>
                                    <span>Shipping Address</span>
                                </div>
                            </AccordionTrigger>
                            <AccordionContent>
                                <div className='space-y-4'>
                                    {/* <div className='text-xs text-primary'>Shipping Address</div> */}
                                    <ShippingAddressForm onSubmit={(e: any) => {
                                        dispatch(setOrderDetails(e))
                                        setactiveTab("payment_details")
                                    }} initial_values={INITIAL_SHIPPING_ORDER_FORM_VALUES} />
                                </div>
                            </AccordionContent>
                        </AccordionItem>
                        <AccordionItem value="payment_details" onClick={() => setactiveTab("payment_details")} >
                            <AccordionTrigger>
                                <div className='flex items-center  space-x-2'>
                                    <span><IoIosCheckmarkCircle size={24} /></span>
                                    <span>Payment Details</span>
                                </div>
                            </AccordionTrigger>
                            <AccordionContent>
                                <div>
                                    {/* <div className='text-xs text-primary'>Payment details</div> */}
                                    <PaymentForm onSubmit={handleCreateOrder} />
                                </div>
                            </AccordionContent>
                        </AccordionItem>
                    </Accordion>
                </div>
                <div className='col-span-2 bg-gray-50 p-4 space-y-2'>
                    <div className='flex justify-between  text-lg font-semibold'>
                        <div>Total</div>
                        <div>₹ {checkDecimal(totalPrice || 0, true)}</div>
                    </div>
                    <div className='text-xs text-primary '>Products Summary</div>
                    <ScrollArea className='max-h-[350px] flex flex-col space-y-2'>
                        {
                            [...orderItems, ...newCartItems].map((d: any) => <ProductItem {...d} />)
                        }
                    </ScrollArea>
                </div>
            </div>
            <LoadingDialog isOpen={isLoading} message='Creating Order' />
        </div>
    )
}

export default CreateOrderPage