import { useEffect, useState } from "react";

import { AlignJustify, CircleUserRound } from "lucide-react";
import { useAuth } from "src/modules/Auth/Core";
import Login from "src/modules/Auth/Login";
import Registration from "src/modules/Auth/Registration";
import Modal from "src/shadcn/components/custom/Modal";
import {
  Button,
  Separator,
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "src/shadcn/components/ui";

import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  catchAsync,
  checkDecimal,
  cn,
  HEADER_MAIN_REFER_NOW,
  ICON_WALLET,
  IS_SUCCESS_RESPONSE,
} from "src/helpers";
import { MAIN_ROUTES_CONST } from "src/navigations/modules";
import {
  getTempDataRootState,
  setShowLoginModule,
} from "src/redux/reducers/tempDataSlice";
import { RootState } from "src/redux/store";
import { fetchWalletByUserIDApiRequest } from "src/services/requests/Wallet";
import {
  Sheet,
  SheetContent,
  SheetTrigger,
} from "src/shadcn/components/ui/sheet";
import BrandComponent from "../../components/BrandComponent";
import UserComponent from "../../Default/Header/UserComponent";
import NavigationMenu_R from "./NavigationMenu_R";
// import Refer_Now from '../../../../../public/media/svg/icons/Refer_Now.svg'
type Props = {};

const HeaderMain_R = (props: Props) => {
  const [openAuthDialog, setopenAuthDialog] = useState(false);
  const [fetchingBalance, setfetchingBalance] = useState<boolean>(false);
  const [walletDetails, setwalletDetails] = useState<any>(0);
  const [isScrolled, setIsScrolled] = useState(false);
  const [open, setopen] = useState(false);

  const [logoinTab, setLoginTab] = useState<string>("login");
  // Track scroll position and update the state when user scrolls
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    handleFetchWallatBalance();

    // Clean up event listener
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const { showLoginModal } = useSelector((state: RootState) =>
    getTempDataRootState(state)
  );

  const dispatch = useDispatch();
  const { currentUser } = useAuth();
  const navigate = useNavigate();

  // const currentUser =  true
  const Refer_Now = HEADER_MAIN_REFER_NOW;

  const handleFetchWallatBalance = catchAsync(
    async () => {
      setfetchingBalance(true);
      return (
        currentUser?.id &&
        (await fetchWalletByUserIDApiRequest(currentUser?.id))
      );
    },
    (result: any) => {
      if (IS_SUCCESS_RESPONSE(result)) {
        setwalletDetails(result.data);
        setfetchingBalance(false);
      }
    },
    () => {
      setfetchingBalance(false);
    }
  );

  return (
    <div className="w-full bg-white">
      <div
        className={cn(
          "w-full  shadow-md fixed top-0 left-0 bg-transparent z-[80] px-4 font-sans bg-white",
          { "bg-white": isScrolled }
        )}
      >
        {/* <BrandComponentReStructure /> */}
        <div className="w-full  lg:max-w-[90vw] h-[80px]  mx-auto flex justify-between items-center pr-4">
          <BrandComponent />

          <div className="flex lg:hidden items-center ">
            <Sheet
              onOpenChange={(val: boolean) => {
                setopen(val);
              }}
              open={open}
            >
              <SheetTrigger asChild>
                <Button variant="outline" className="m-auto p-1">
                  <AlignJustify className="w-8 h-8 " />
                </Button>
              </SheetTrigger>
              <SheetContent>
                <div className="">
                  <NavigationMenu_R setopen={setopen} open={open} />
                  {currentUser ? (
                    <div className="flex space-x-2 items-center px-2">
                      <button
                        className=""
                        onClick={() => {
                          navigate("/" + MAIN_ROUTES_CONST.wallet);
                          if (open) {
                            setopen && setopen(!open);
                          } else if (!open) {
                            setopen && setopen(!!open);
                          } else return;
                        }}
                      >
                        <div className="flex items-center space-x-2">
                          <img
                            src={ICON_WALLET}
                            style={{ width: "40px", height: "40px" }}
                          />
                          <span>₹ 0</span>
                        </div>
                      </button>
                      <Separator orientation="vertical" />
                      {/* <UserComponent /> */}
                    </div>
                  ) : (
                    <div className="flex flex-col space-y-2 ml-4 sm:ml-0">
                      <Button
                        size={"sm"}
                        className="w-28 uppercase space-x-2 align-middle"
                        onClick={() => {
                          setopenAuthDialog(true);
                          if (open) {
                            setopen && setopen(!open);
                          } else if (!open) {
                            setopen && setopen(!!open);
                          } else return;
                        }}
                      >
                        <CircleUserRound className="w-5 h-5" />
                        <span>Login</span>{" "}
                      </Button>
                      <Button
                        size={"sm"}
                        className="w-28 uppercase space-x-2 align-middle bg-theamblack hover:bg-theamblack-foreground "
                        onClick={() => {
                          navigate("/" + MAIN_ROUTES_CONST.refer_now);
                          if (open) {
                            setopen && setopen(!open);
                          } else if (!open) {
                            setopen && setopen(!!open);
                          } else return;
                        }}
                      >
                        <img
                          src={Refer_Now}
                          alt="Refer_Now"
                          className="w-4 h-4"
                        />
                        <span>Refer Now</span>
                      </Button>
                    </div>
                  )}

                  <Modal
                    isOpen={openAuthDialog || showLoginModal}
                    className={"max-w-4xl"}
                    onChangeIsOpen={(e: any) => {
                      setopenAuthDialog(e);
                      dispatch(setShowLoginModule(e));
                    }}
                  >
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                      <div>
                        <Login isOpenDialog={setopenAuthDialog} />
                      </div>
                      <div>
                        <Registration isOpenDialog={setopenAuthDialog} />
                      </div>
                    </div>
                  </Modal>
                </div>
              </SheetContent>
            </Sheet>
            {currentUser ? (
              <div className=" md:hidden h-full flex items-center ">
                <Separator orientation="vertical" />
                <UserComponent />
              </div>
            ) : null}
          </div>

          <div className="hidden lg:flex space-x-4">
            <NavigationMenu_R />
            {currentUser ? (
              <div className="flex space-x-2 items-center px-2">
                <button
                  className=""
                  onClick={() => navigate("/" + MAIN_ROUTES_CONST.wallet)}
                >
                  <div className="flex items-center space-x-2">
                    <img
                      src={ICON_WALLET}
                      style={{ width: "40px", height: "40px" }}
                    />
                    <span>
                      ₹{" "}
                      {walletDetails?.total_amount
                        ? checkDecimal(walletDetails?.total_amount, true)
                        : 0}
                    </span>
                  </div>
                </button>
                <div className=" hidden md:h-full md:flex md:items-center ">
                  <Separator orientation="vertical" />
                  <UserComponent />
                </div>
              </div>
            ) : (
              <div className="space-x-1 flex items-center justify-center">
                <Button
                  size={"sm"}
                  className=" uppercase space-x-2 align-middle"
                  onClick={() => dispatch(setShowLoginModule(true))}
                >
                  <CircleUserRound className="w-5 h-5" />
                  <span>Login</span>{" "}
                </Button>
                <Button
                  size={"sm"}
                  className=" uppercase space-x-2 align-middle bg-theamblack hover:bg-theamblack-foreground "
                  onClick={() => navigate("/" + MAIN_ROUTES_CONST.refer_now)}
                >
                  <img src={Refer_Now} alt="Refer_Now" className="w-4 h-4" />
                  <span>Refer Now</span>
                </Button>
              </div>
            )}

            <Modal
              isOpen={showLoginModal}
              className={"w-full"}
              onChangeIsOpen={(e: any) => {
                setopenAuthDialog(e);
                dispatch(setShowLoginModule(e));
              }}
            >
              {/* <div className="grid md:grid-cols-2 gap-4 h-[400px] md:h-min overflow-y-scroll  md:overflow-y-hidden">
                <div>
                  <Login isOpenDialog={setopenAuthDialog} />
                </div>
                <div>
                  <Registration isOpenDialog={setopenAuthDialog} />
                </div>
              </div> */}

              <Tabs
                defaultValue="account"
                className=""
                onValueChange={(e: string) => setLoginTab(e)}
              >
                <TabsList className="grid w-full grid-cols-2">
                  <TabsTrigger value="login">Login</TabsTrigger>
                  <TabsTrigger value="signUp">Sign Up</TabsTrigger>
                </TabsList>
                <TabsContent value="login" className="h-[300px]">
                  <Login isOpenDialog={setopenAuthDialog} />
                </TabsContent>
                <TabsContent value="signUp" className="h-[300px]">
                  <Registration isOpenDialog={setopenAuthDialog} />
                </TabsContent>
              </Tabs>
            </Modal>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeaderMain_R;
