import React from 'react'
import { IMG_CONTACT_US_BANNER, IMG_FAQ_BANNER } from 'src/helpers'

type Props = {}

const TermsAndConditions = (props: Props) => {
  return (
    <div>
      <div className='w-full h-[300px] overflow-hidden' style={{ backgroundImage: "url(" + IMG_CONTACT_US_BANNER + ")", backgroundSize: "cover" }} >
        <div className='flex flex-col justify-center items-center h-[100%]'>
          <div className='text-primary text-4xl text-center' >Terms and Conditions</div>
        </div>
      </div>
      <div className='max-w-[80vw] my-[50px] space-y-[50px] mx-auto'>
        {/* content */}

        <div>
          <p>This document is an electronic record in terms of Information Technology Act, 2000 and rules thereunder as applicable and the provisions pertaining to electronic records in various statutes as amended by the Information Technology Act, 2000. This electronic record is generated by a computer system and does not require any physical or digital signatures. By clicking on the “I Agree” button, You are consenting to be bound by these terms and Conditions for using the Electreecity Marketplace for the purpose of receiving business leads to the Users. 
          Please ensure that you read and understand all these terms & conditions before you use the service of the Electreecity Marketplace, as you shall be bound by all terms & conditions contained herein upon clicking on the “I Agree” button. If You do not accept any of the Terms & Conditions of Electreecity Marketplace, please do not use the Electreecity Marketplace or avail any of the services being provided therein. your agreement to the terms & conditions of Electreecity marketplace shall operate as a binding agreement between you and oct in respect of the services of the Electreecity Marketplace</p><br/>
          <p>Terms and conditions for the use of the online marketplace <strong>“www.electreecity.in”</strong> as well as the verification, brokerage, and other services of  Orange Current Technologies.</p>
          <br />
          <strong className='max-w-[80vw] my-[50px] space-y-[50px] mx-auto gray'>   1 Scope </strong><br /><br />

          1.	These terms and conditions apply to the use of the online marketplace www.electreecity.in operated by Orange Current Technologies Pvt Ltd (hereinafter also referred to as OCT) and for the verification, brokerage, and other services of OCT in connection with the realization of Solar renewable energy plants (“RE plants”). <br /><br />
          2.	“User” according to these terms and conditions is a natural person, a legal entity or a partnership having legal capacity, acting in the performance of a commercial or a self-employed occupational activity when using the online marketplace and having received the user specific access and usage rights for the online marketplace from OCT after registration.<br /><br />
          3.	These terms and conditions apply exclusively. OCT expressly rejects any conflicting terms and conditions or terms deviating from our own unless expressly agreed to in writing.<br /><br />


          <strong className='max-w-[80vw] my-[50px] space-y-[50px] mx-auto gray'>   2 General description of services </strong><br /><br />


          1.	OCT offers an online marketplace with a protected area for contact, evaluation, and contract initiation in connection with the purchase and sale of objects. “Objects” according to the online marketplace are (i) project rights for Solar RE plants, (ii) turn-key Solar RE plants under contruction, and (iii) operational Solar RE plants iv) Lease the rooftop and v) Rent or sale the Land.<br /><br />
          2.	“Project rights” in this connection are all transferable legal positions connected to the realization of a Solar RE system. This includes, for example, authorizations of Solar Projects as per Indian law, license agreements, agreements for the procurement of components, technical planning, expert opinions, and studies.<br /><br />
          3.	After registration, users have the option to either offer objects for sale on the online marketplace as vendors (also referred to as “sellers” on the online marketplace), or to look at these offers as potential buyers (also referred to as “buyers” on the online marketplace). Potential buyers may transmit bids or additional questions to OCT for transfer to any user. Direct negotiations between vendors and potential buyers are only possible after unlocking of the specific project documentation by OCT.<br /><br />
          4.	Purchase agreements may only and immediately be made between a vendor and a potential buyer and are   registered outside of the online marketplace. OCT does not enter any bids and does not accept bids of users on its own account.<br /><br />
          5.	OCT is neither owner of the objects offered on the online marketplace nor owner of the legal position, and it cannot influence the fulfillment of the agreement made between the users of the online marketplace. OCT cannot be held liable for any success a buyer expects from a seller’s performance.<br /><br />

          <strong className='max-w-[80vw] my-[50px] space-y-[50px] mx-auto gray'>   3 Registration, conclusion of  registration </strong><br /><br />
          1.	The potential user must provide complete and true information on the online registration form for the registration including personal access data consisting of a user name and a password. Upon transmission of the registration form and the acceptance of these terms and conditions the user profile of the potential user for the online marketplace will be created and an activation email will be sent to the email address of the user. When the potential user confirms the email address via the activation link contained in the activation email, the user profile is activated and a registration is completed . Upon validation of the professional status of the potential user via the provision of additional documentation, OCT will provide the potential user with specific access and usage rights to the online marketplace.<br /><br />
          2.	The registration continues indefinitely and may be cancelled at any time via the deletion of the user profile by either party. All obligations accepted for certain periods of time, such as the commission obligation of follow-up business according to    5, Sub-Section 7, of these terms and conditions, survive the cancellation.<br /><br />
          <strong className='max-w-[80vw] my-[50px] space-y-[50px] mx-auto gray'>   4 General usage terms and conditions </strong><br /><br />
          1.	The user may only use the services offered by OCT on the online marketplace, especially all data and information, for his own and immediate use. The user may not transfer any received project, product, offer, company, or personal data to Third Parties or sell those data. Operating or affiliated companies named in the registration and represented by the user are not considered as Third Parties.<br /><br />
          2.	The user must inform OCT immediately of any change of his company data (especially as pertaining to a change of the company’s legal form), personal, and address data.<br /><br />
          3.	Legitimization information, i.e., access data, must be kept away from Third Parties and protected from unauthorized access.<br /><br />
          4.	The user assumes liability for all agreements or law violations performed or caused using his access data. The user must inform OCT immediately about any misuse of access data in order to block these data.<br /><br />
          <strong className='max-w-[80vw] my-[50px] space-y-[50px] mx-auto gray'>   5 Confidentiality agreement for vendors </strong><br /><br />
          1.	In connection with the objects offered by a vendor, the vendor will provide OCT with confidential information. OCT assures that all information regarding planned or existing objects will be treated confidentially. The vendor allows OCT to use the information provided in connection to the objects within the framework of finding one or more Potential Buyers for them (“the purpose”). In this context, information is, for example, names and personal data of the parties involved, planning documents, technical and commercial data, and presentation and expert opinions, documented in writing or electronically.<br /><br />
          2.	Distribution to Third Parties, that are not users of the online marketplace, requires the vendor’s approval, except if there is a legal duty and the data is passed on to persons obliged to maintain professional secrecy (such as auditors and lawyers), or if the distribution is directly connected to the purpose. In the latter case, Third Parties must also be obligated to confidentiality.<br /><br />
          3.	The vendor assures that all information about objects offered by other Vendors on the online marketplace is treated confidentially. Furthermore, the Vendor will treat all information confidentially which is provided to him about technical functionality, structuring, and presentation of OCT’s online marketplace.<br /><br />

          <strong className='max-w-[80vw] my-[50px] space-y-[50px] mx-auto gray'>   6 Confidentiality agreement for Buyers </strong><br /><br />
          1.	The Potential Buyer agrees that he will treat all information regarding planned or existing objects confidentially for a period of five years and only to use this information for his own purposes. In this context, information is, for example, names and personal data of the parties involved, planning documents, technical and commercial data, and presentation and expert opinions, documented in writing or electronically. Furthermore, the Potential Buyer will treat all information confidentially which is provided to him about technical functionality, structuring, and presentation of OCTOCT’s online marketplace.<br /><br />
          2.	The Potential Buyer must inform his employees about the confidentiality clause and must ensure that they adhere to it. Distribution to Third Parties requires OCTOCT’s approval, except if there is a legal duty and the data is passed on to persons obliged to maintain professional secrecy (such as auditors and lawyers), or if the distribution is directly connected to financing or approval issues.  In the latter case, Third Parties must also be obligated to confidentiality.<br /><br />
          3.	The acknowledgment does not entitle the Potential Buyer to any claims, title, licenses or other rights to the confidential information. The confidential information is no assurance, insurance, or warranty by OCT. In case of a violation or threatened violation of this agreement, OCT reserves all legal remedies, including the enforcement of direct or indirect damage claims and injunctive relief.<br /><br />

          <strong className='max-w-[80vw] my-[50px] space-y-[50px] mx-auto gray'>   7 Commission </strong><br /><br />
          1.	OCT charges a commission to buyers of objects for connecting vendors and potential buyers and for any resulting business transactions.<br /><br />
          2.	The amount of the commission is defined according to the price list  which is valid and published on www.electreecity.in at the due date and depends on the total installable or already installed (i) nominal power of the photovoltaic modules (in case of solar power projects or plants) or (ii) total AC power (in case of other electricity generation projects or plants).<br /><br />
          3.	Commission is payable as per mutually agreed Terms and Conditions, after one or more agreements (e.g., purchase agreements, leasing agreements or similar) have been concluded for the transaction of an object between the vendor of this object and the buyer and the agreement/s have become legally effective (according to these terms and conditions those agreements where conditions precedent are not fulfilled are deemed to be understood as not legally effective).<br /><br />
          4.	An entitlement to commission does not arise if and when the potential buyer / buyer does already know the object which is offered to him through the online marketplace www.electreecity.in prior to the unlocking of the project documentation, and he notifies and proves this fact to OCT in writing within 14 days after unlocking the relevant project documentation.<br /><br />
          5.	All prices mentioned in these terms and conditions and in individual offers exclusive of all taxes, duties etc the .<br /><br />
          6.	If the fulfillment of a legally effective agreement between a vendor and a buyer fails, it does not influence the commission claim of OCT, as long as the original transaction of the object has been concluded (Example: This may apply for a case where additional services are agreed upon between Vendor and Buyer and these services are not fulfilled later on). The same applies if one of the parties withdraws from the agreement.<br /><br />
          7.	The obligation to pay commission exists for each follow-up business transaction concluded between a vendor and a buyer of one of OCT’s proven or procured initial business transactions within one year from the date of the conclusion of the initial business transaction. This applies independent of whether a follow-up business transaction was concluded via the online marketplace. The amount of the commission for follow-up business transactions is 100% of the commission determined in Section 2 for the first two follow-up business transactions following the initial business transaction and 50% of the commission determined in Section 2 for all additional business transactions.<br /><br />

          <strong className='max-w-[80vw] my-[50px] space-y-[50px] mx-auto gray'>   8 Special conditions for vendors </strong><br /><br />
          1.	A vendor may offer his objects on the online marketplace. The vendor must post his offer in the corresponding category and must provide an accurate and complete description. He must especially point out any conditions, rights to withdraw, and other provisos. The description of objects includes entering all project information as well as uploading and/or providing any project documentation. The vendor must provide a true description of all characteristics and features as well as limitations, reducing the value of the object offered, which are important for the purchase decision. OCT is not obligated to check the vendor’s information. The project information fields may not contain any information allowing direct contact with the vendor (such as telephone and fax number, email or web addresses, social media contact details, etc.).<br /><br />
          2.	OCT has the right to check any offer before it is made public on the online marketplace and may at any time refuse the publishing of an offer.<br /><br />
          3.	The vendor is obligated to have his offer deleted or changed immediately as soon as the offered object is sold in whole or in parts or is no longer available in whole or in parts for other reasons. All changes to an object must be shown on the online marketplace within 10 days by correctly changing the project information and/or project documentation. OCT has the right to check any change of an offer and may at any time refuse the change of an offer.<br /><br />
          4.	The vendor is obliged to inform OCT truthfully and completely about the current state of the negotiations and/or the signing of an agreement with the potential buyer at regular intervals. This applies in particular, but not limited to, upon written request by OCT and for follow-up business transactions according to    7, Sub-Section 7.<br /><br />
          5.	The vendor is obliged to delete an offer, as soon as the object is no longer available. This is especially the case, after one or more agreements (e.g., purchase agreements, leasing agreements or similar) have been concluded for the transaction of the object and the agreement/s have become legally effective.<br /><br />
          6.	After the deletion of an offer the vendor, upon written request by OCT, is obliged to name truthfully the buyer of an object.<br /><br />
          7.	A violation of the aforementioned obligations results in blocking and exclusion from OCT services. A violation of the obligations to inform according to    8, Sub-Sections 4 and 6 may result in damage claims against the vendor.<br /><br />


          <strong className='max-w-[80vw] my-[50px] space-y-[50px] mx-auto gray'>   9 Special conditions for potential buyers </strong><br /><br />
          1.	In order for a registration procedure to be concluded, a potential buyer must prove his intention to buy to OCT.<br /><br />
          2.	After registration, a potential buyer has access to project information about all objects offered on the online marketplace.<br /><br />
          3.	After registration, a potential buyer does not yet have access to an object’s project documentation. The potential buyer may apply for unlocking of project documentation as well as access to the contact data of each vendor for a certain object. After immediate and successful evaluation of this application, the potential buyer is given access to an object’s project documentation.<br /><br />
          4.	At the request of the potential buyer, OCT establishes the contact with the vendor of an object, which was previously unlocked for the potential buyer.<br /><br />
          5.	After unlocking of the project documentation, the potential buyer is obliged to inform OCT truthfully and completely about the current state of the negotiations and/or the signing of an agreement with the vendor at regular intervals. This applies in particular, but not limited to, upon written request by OCT and for follow-up business transactions according to    5, Sub-Section 7.<br /><br />
          6.	Users of the online marketplace may not further use the contact, offer, project, or product data received through the use of the online marketplace for their own purpose or a Third Party’s purpose free of charge or against payment and may not publish, distribute, transfer, save, or duplicate offer and address data free of charge or against payment. It is also prohibited to send advertising to registered users. A violation of the aforementioned obligations results in blocking and exclusion from OCT services. This does not affect any existing confidentiality agreements between the potential buyer and OCT.<br /><br />

          <strong className='max-w-[80vw] my-[50px] space-y-[50px] mx-auto gray'>   10 Responsibility for content and indemnification     </strong><br /><br />
          1.	As operator of the online marketplace, OCT permits the user only to publish his own offers. Prior to the publication of the offers, OCT is not obligated to check the content or legal content of any offer. OCT is not the owner of the information provided there.<br /><br />
          2.	The user has sole responsibility regarding contents, legal, factual, and other informational contents. The user agrees to adhere to applicable law when selecting, creating, and publishing any offers and for the transmission, release, and publication of any offer information and documentation and to assure that no general protection act, penal laws, regulations, or Third Party rights (especially commercial protection laws, such as patent, trademark, labeling, name, or personality laws) are violated.<br /><br />
          3.	The user agrees to indemnify and hold harmless OCT from and against any and all losses, liabilities, damages, claims, and expenses (including but not limited to attorneys’ fees and other costs of defense) that OCT may incur as a result of any claim by user or by any Third Party arising out of or in connection with the publication of data and offers.<br /><br />

          <strong className='max-w-[80vw] my-[50px] space-y-[50px] mx-auto gray'>   11 Refusal and removal of database entries/blocking of an account </strong><br /><br />
          OCT may block a user’s offer or his access data if the user violates the provisions of these terms and conditions, morality, applicable law, or Third Party rights, or if OCT has a legitimate interest in blocking the user. OCT also reserves the right to refuse the acceptance of new offers at its sole and exclusive discretion.<br /><br />
          <strong className='max-w-[80vw] my-[50px] space-y-[50px] mx-auto gray'>   12 Limitation of liability </strong><br /><br />
          1.	OCT does not warrant, and is not liable for, the accuracy and/or completeness of any information, advice, or recommendation contained or accessible on its web pages.<br /><br />
          2.	OCT also specifically disclaims any liability for any damages arising from false data transfer, data loss, or false reproduction of a database entry after the original entry of data, unless such damages are ultimately determined to be attributable solely to the willful misconduct or gross negligence of OCT, its legal representatives, or agents.<br /><br />
          3.	OCT is liable vis-a-vis companies for damages only, except if essential contractual obligations (obligations required to enable the orderly execution of the agreement and the adherence to which the parties to the agreement rely), of and to the extent that legal representatives or members of the management of OCT are guilty of intent or gross negligence. The aforementioned limitation of liability applies to consequential damages, especially to lost profits. Within the framework of the liability provisions described above, OCT’s liability is limited to typical foreseeable damages at the time of the conclusion of the agreement.<br /><br />
          4.	The aforementioned limitations of liability and exclusions do not apply to claims related to the law on product liability, damages from injuries to one’s life, body, health or mandatory statutory obligations.<br /><br />
          5.	OCT is not liable for any hardware and software errors on servers used for the operation and/or administration of the online marketplace, immediate errors, such as data destruction on the user’s local server and system, transfer of viruses, non availability, and damages to hardware and software on the user’s local server and system. No claim exists to the completeness, correctness or reproducibility (i.e. in case of destruction of the databases used for the operation of the online marketplace).<br /><br />

          <strong className='max-w-[80vw] my-[50px] space-y-[50px] mx-auto gray'>   13 Place of performance and jurisdiction/ applicable law </strong><br /><br />
          1.	Place of performance for payments and delivery is Mumbai, India.<br /><br />
          2.	This agreement is based on the law of the India.<br /><br />
          3.	If the contractual partner of OCT acts as a principal, he/she is a legal person under public law or a federal special fund under public law and the place of jurisdiction for all disputes arising from the contractual relationship with the user is Mumbai, India.    14 Changes to these terms and conditions and/or the price list, severability clause, language.<br /><br />
          4.	OCT reserves the right to change these terms and conditions and/or the price list at any time and for any reason. The user will receive a copy of the changed terms and conditions and/or the price list via e-mail at least two weeks prior to their taking effect.  If the user does not object to the effectiveness of the new terms and conditions and/or the price list within two weeks after receiving them via e-mail, the changed terms and conditions and/or the price list are deemed accepted.<br /><br />
          5.	In case individual provisions of these terms and conditions are determined by a court of competent jurisdiction to be invalid or unenforceable in whole or in part, or will lose their validity at a later point in time, these terms and conditions shall be construed so that the remaining provisions shall not be affected thereby but shall remain in full force and effect. Any invalid or unenforceable provision shall be deemed to be replaced by one which is closest to the economic sense and purpose of the invalid or unenforceable provision and has legal effect. The same applies to any gaps in the terms and conditions.<br /><br />
          {/* 6.	These terms and conditions were originally written in the German language and may be translated into other languages. The German version has priority in any case for the business relationships between OCT and the user and the interpretation of individual clauses.<br /><br /> */}

        </div>
      </div>
    </div>
  )
}

export default TermsAndConditions