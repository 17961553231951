import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import axios, { AxiosError } from 'axios';
import { RootState } from '../store';
import { toast } from 'src/shadcn/components/ui/use-toast';
import { getProjectDetailsByIDRequestApi } from 'src/services/requests/Projects';


interface tempDataReducerState {
    email_for_validate_otp: string
    transaction_id: string,
    session: string,
    showLoginModal: boolean
    quotationDetails: any,
    userLocationDetails: any
    fetchingLocation: boolean
    selectBannerIndex: number,
    setBannerIndex: number | undefined,
    selectedService: any
    mapData: any,
    residentialUserDetails: any
    lastUserRoute: any
}

const initialState: tempDataReducerState = {
    email_for_validate_otp: "",
    transaction_id: "",
    session: "",
    showLoginModal: false,
    quotationDetails: null,
    userLocationDetails: null,
    fetchingLocation: false,
    selectBannerIndex: 0,
    setBannerIndex: undefined,
    selectedService: null,
    mapData: undefined,
    residentialUserDetails: {},
    lastUserRoute: undefined

};


export const fetchGeoLocationAction: any = createAsyncThunk(
    'temp/fetchGeoLocation',
    async () => {
        try {
            const location = window.navigator && window.navigator.geolocation
            if (location) {
                location.getCurrentPosition((position) => {
                    console.log({ latitude: position.coords.latitude, longitude: position.coords.longitude });
                    return { latitude: position.coords.latitude, longitude: position.coords.longitude }
                }, (error) => { })
            }
        } catch (err) {
            const error = err as Error | AxiosError;
            if (axios.isAxiosError(error)) {
                if (error) {
                    const status = error.response?.status;
                    toast({
                        variant: "destructive",
                        title: "Uh oh! Something went wrong.",
                        description: error.response?.data?.message,
                    })
                }
            } else {
                toast({
                    variant: "destructive",
                    title: "Unexpected error",
                    description: error?.message,
                })
            }
        }
    }
);



const tempDataSlice = createSlice({
    name: 'tempDataSlice',
    initialState,
    reducers: {
        setEmailForValidatOtp: (state, action: PayloadAction<any>) => {
            state.email_for_validate_otp = action.payload;
        },
        resetEmailForValidatOtp: (state) => {
            state.email_for_validate_otp = "";
        },
        setTransactionID: (state, action: PayloadAction<any>) => {
            state.transaction_id = action.payload;
        },
        resetTransactionID: (state) => {
            state.transaction_id = "";
        },
        setSession: (state, action: PayloadAction<any>) => {
            state.session = action.payload;
        },
        resetSession: (state) => {
            state.session = "";
        },
        setShowLoginModule: (state, action: PayloadAction<any>) => {
            state.showLoginModal = action.payload;
        },
        resetShowLoginModule: (state) => {
            state.showLoginModal = false;
        },
        setQuotationdetails: (state, action: PayloadAction<any>) => {
            state.quotationDetails = action.payload;
        },
        resetQuotationdetails: (state) => {
            state.quotationDetails = null;
        },
        setUserLoaction: (state, action: PayloadAction<any>) => {
            console.log({ payload: action.payload });

            state.userLocationDetails = action.payload;;
        },
        resetSelectedBannerIndex: (state) => {
            state.selectBannerIndex = 0;
        },
        setSelectedBannerIndex: (state, action: PayloadAction<any>) => {
            state.selectBannerIndex = action.payload;;
        },
        resetSetBannerIndex: (state) => {
            state.setBannerIndex = undefined;
        },
        setSetBannerIndex: (state, action: PayloadAction<any>) => {
            state.setBannerIndex = action.payload;;
        },
        setSelectedService: (state, action: PayloadAction<any>) => {
            state.selectedService = action.payload;;
        },
        resetSelectedService: (state) => {
            state.selectedService = null;
        },
        setMapData: (state, action: PayloadAction<any>) => {
            state.mapData = action.payload;;
        },
        resetMapData: (state) => {
            state.mapData = null;
        },
        setResidentialUserDetails: (state, action: PayloadAction<any>) => {
            state.residentialUserDetails = action.payload;;
        },
        resetResidentialUserDetails: (state) => {
            state.residentialUserDetails = null;
        },
        setlastUserRoute: (state, action: PayloadAction<any>) => {
            state.lastUserRoute = action.payload;;
        },
        resetlastUserRoute: (state) => {
            state.lastUserRoute = null;
        },
        
    },
    extraReducers: (builder) => {
        builder.addCase(fetchGeoLocationAction.pending, (state: tempDataReducerState) => {
            state.fetchingLocation = true;
        });
        builder.addCase(fetchGeoLocationAction.fulfilled, (state: tempDataReducerState, action: any) => {
            console.log({ "fetchGeoLocationAction": action.payload });
            state.fetchingLocation = false;

        });
        builder.addCase(fetchGeoLocationAction.rejected, (state: tempDataReducerState, action: any) => {
            state.fetchingLocation = false;
        });
    },

});

export const {
    setEmailForValidatOtp,
    resetEmailForValidatOtp,
    setTransactionID,
    resetTransactionID,
    setSession,
    resetSession,
    setShowLoginModule,
    resetShowLoginModule,
    setQuotationdetails,
    resetQuotationdetails,
    resetSelectedBannerIndex,
    setSelectedBannerIndex,
    resetSetBannerIndex,
    setSelectedService,
    resetSelectedService,
    setSetBannerIndex,
    setlastUserRoute,
    resetlastUserRoute,
    setUserLoaction, setMapData, resetMapData, setResidentialUserDetails, resetResidentialUserDetails } = tempDataSlice.actions;
export const getTempDataRootState = (state: RootState) => state.tempDataReducer;
export default tempDataSlice.reducer;