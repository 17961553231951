import { ColumnDef } from "@tanstack/react-table";
import { Task } from "../data/schema";
import { DataTableColumnHeader } from "./data-table-column-header";
import { DataTableRowActions } from "./data-table-row-actions";
import { Button } from "src/shadcn/components/ui";
import { X } from "lucide-react";
import { checkDecimal } from "src/helpers";
import dayjs from "dayjs";


export const columns: ColumnDef<Task>[] = [
  {
    accessorKey: 'name',
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Name" />
    ),
    cell({ row: { original: { name} } }) {
      return <div className="ml-2">{name||"-"}</div>
    },
  },
  {
    accessorKey: 'phonenumber',
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Phone Number" />
    ),
    cell({ row: { original: { phonenumber} } }) {
      return <div className="ml-2">{phonenumber||"-"}</div>
    },
  },
  {
    accessorKey: 'project_type',
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Project Type" />
    ),
  },
  {
    accessorKey: 'type',
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Type" />
    ),
  },
  {
    accessorKey: 'project_id',
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Project Id (#)" />
    ),
  },

  {
    accessorKey: 'createdAt',
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Created At" />
    ),
    cell({ row: { original: { createdAt} } }) {
      return createdAt ? dayjs(new Date(createdAt)).format('DD-MM-YYYY HH:mm:ss A') : null
    },
  },
  {
    accessorKey: 'description',
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Reason" />
    ),
    cell({ row: { original: { description} } }) {
      return <div className="">{description}</div>
    },
  },



];
