import { MAIN_ROUTES_CONST } from "src/navigations/modules";

export const OVERVIEW = {
    title: "Solar EPC for Societies",
    description: "Comprehensive Engineering, Procurement, and Construction (EPC) services for end-to-end solar project execution.",
    video: "https://www.youtube.com/watch?v=dQw4w9WgXcQ",
    img: "",
    targetPage: MAIN_ROUTES_CONST.marketplace+ "?projectCategoryType=Lease Roof Areas"
  };
  
  export const KEY_FEATURES = [
    { icon: "", title: "Maximize Space Efficiency", description: "Rooftop solar systems use unused roof space to generate energy, making them ideal for urban buildings with limited outdoor area." },
    { icon: "", title: "Reduce Energy Costs", description: "Generating your own electricity can significantly reduce or eliminate your reliance on the grid, leading to substantial savings on bills." },
    { icon: "", title: "Quick and Easy Installation", description: "Rooftop solar systems are typically easier and faster to install compared to ground-mounted systems, with minimal disruption to your property or daily operations." },
    { icon: "", title: "Grid Independence", description: "Rooftop solar systems with battery storage provide power during grid outages, ensuring an uninterrupted energy supply." },
  ];

  export const FAQ = [
    {
      "question": "What is Rooftop solar?",
      "answer": "Rooftop solar involves installing solar panels on residential or commercial rooftops to generate electricity for on-site use."
    },
    {
      "question": "How long do rooftop solar panels last?",
      "answer": ""
    },
    {
      "question": "Can I sell excess electricity back to the grid?",
      "answer": ""
    },
    {
      "question": "Do I need a battery for rooftop solar?",
      "answer": ""
    },
    {
      "question": "Are there maintenance requirements?",
      "answer": ""
    },
    {
      "question": "What incentives are available for rooftop solar?",
      "answer": ""
    }
  ]