import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { COMMERCIAL_AND_INDUSTRIAL_PROJECT_OPTIONS, parseFiltersDataIntoQuery, PROJECT_TYPES, RESIDENTIAL_AND_SOCIETY_PROJECT_OPTIONS, STATES_OPTIONS } from 'src/helpers'
import FilterFormComponent from 'src/modules/FilterModule/FilterFormComponent'
import NewFilterLayout from './NewFilterLayout'

type Props = {
    onChange: (e: any) => void
    setopen?: (val: boolean) => void,
    open?: boolean
}


const FILTERS = [
    {
        name: 'Buy/Sell',
        dataKey: "buySell",
        type: 'dropdown',
        // optionsType: "multiselect",
        options: ["Buy", "Sell"].map((d: any) => ({ label: d, value: d })),
        btnWidth:100
    },
    {
        name: 'Select Services',
        dataKey: "consumerType",
        conditional:true,
        type: 'dropdown',
        options: [{
            label: 'Residential & Societies',
            value: 'residential',
        }, {
            label: 'Commercial & Industrial',
            value: 'commercial',
        }],
        btnWidth:200
    },
    {
        name: 'Select Products',
        dataKey: "projectCategoryType",
        type: 'list',
        optionsType: "multiselect",
        conditionalKey:"consumerType",
        conditionalValue:"commercial",
        options: COMMERCIAL_AND_INDUSTRIAL_PROJECT_OPTIONS
    },
    {
        name: 'Select Products',
        dataKey: "projectCategoryType",
        type: 'list',
        optionsType: "multiselect",
        conditionalKey:"consumerType",
        conditionalValue:"residential",
        options: RESIDENTIAL_AND_SOCIETY_PROJECT_OPTIONS
    },
    {
        name: 'Project Type',
        dataKey: "projectType",
        type: 'list',
        optionsType: "multiselect",
        options: PROJECT_TYPES
    },
    {
        name: 'State',
        dataKey: "state",
        type: 'dropdown',
        options: STATES_OPTIONS
        // optionsType: "singleselect",
        // options: []
    },
    {
        name: 'Project ID',
        dataKey: "projectId",
        type: 'text',
    },
    // {
    //     dataKey: "projectName",
    //     name: 'Project Name',
    //     type: 'text',
    //     regex: NAME_REGEX
    // },
    {
        name: 'Investments',
        dataKey: "projectCost",
        type: 'range',
        min: 0,
        max: 50000000,
        steps: 1000000,
        prefix: "₹",
         unit:"Rs"
    },
    {
        name: 'Project Size',
        dataKey: "projectSize",
        type: 'range',
        min: 0,
        max: 500000,
        steps: 100000,
        suffix: "kW",
        unit:"KW"
    },

];


const RESET_FILTER = {
    "projectCategoryType": [],
    "buySell": "",
    "projectType": [],
    "investments": {
        min: 0,
        max: 0
    },
    "state": "",
    "project_id": "",
    "projectName": "",
    "projectSize": {
        min: 0,
        max: 0
    },
}


const FilterMain = ({ onChange, open, setopen }: Props) => {

    const { search } = useLocation()

    const [selectedFilters, setselectedFilters] = useState<{
        "projectCategoryType": string[],
        "projectType": string[],
        "buySell": string,
        "investments": {
            min: number,
            max: number
        },
        "state": string,
        "project_id": string,
        "projectName": string,
        "projectSize": {
            min: number,
            max: number
        }
    }>(RESET_FILTER)

    console.log({ search });

    useEffect(() => {
        if (search) {
            let filterData = search ? search.replace("?", "").split("&").reduce((acc: any, curr: any) => {
                const [key, value] = curr.split("=");
                if (["projectCategoryType", "residentialAndSocieties", "projectType", "buySell"].includes(key) && value) {
                    acc[key as keyof object] = value ? value.split(",").map((d: any) => decodeURI(d)) : [];
                }
                else if (["investments"].includes(key) && value) {
                    let [min, max] = value.split(":")
                    acc[key as keyof object] = value ? {
                        min: min,
                        max: max
                    } : {
                        min: 0,
                        max: 0
                    };
                }
                else acc[key as keyof object] = value ? decodeURI(value) : "";
                return acc;
            }, {}) : {};
            console.log({ filterData });
            setselectedFilters({ ...RESET_FILTER, ...filterData })
            onChange(parseFiltersDataIntoQuery(filterData))
        }
        return () => { }
    }, [search])

    return (
        <>
            <div className='lg:hidden'>
                <FilterFormComponent filterData={FILTERS} selectedFilters={selectedFilters} onChange={(e: any) => { onChange(e) }} open={open} setopen={setopen} />
            </div>
            <div className='flex justify-center'>
                <NewFilterLayout filterData={FILTERS} selectedFilters={selectedFilters} onChange={(e: any) => { onChange(e) }} open={open} setopen={setopen} />
            </div>
        </>
    )
}

export default FilterMain