import { useState } from 'react'
import { ANNOTATION_ICON, FormField, IMG_COMMERCIAL_SERVICES_REFERENCES_BG, optionTypes, RESIDENTIAL_AND_SOCIETY_PROJECT_OPTIONS, WELCOME_SERVICE_BG } from 'src/helpers'
import HeroSection from 'src/modules/Welcome/HeroSection'
import { MAIN_ROUTES_CONST } from 'src/navigations/modules'
import { fetchProjectsSummaryApiRequest, fetchTopProjectsByCategoryApiRequest } from 'src/services/requests/Residential'
import { Button } from 'src/shadcn/components/ui'
import { DATA, SOCIETY_DATA } from '../Welcome/Services/ResidentialServices/modules'
import { CUSTOMER_DETAILS_FIELDS, CustomerDetailsFormSchema, REFERENCES_ANALYSIS } from './Component/modules'
import ServiceTile from './Component/ServiceTile'
import useFetchData from './useHandleGetTop5Products'

import { useFormik } from 'formik'
import { INIT_SUBSCRIBE_FORM } from 'src/modules/Layouts/ReStructure/Footer/modules'
import CustomeDialog from 'src/shadcn/components/custom/CustomeDialog'
import RenderFormComponents from 'src/shadcn/components/custom/RenderFormComponents'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { RootState } from 'src/redux/store'
import { getTempDataRootState, setResidentialUserDetails } from 'src/redux/reducers/tempDataSlice'
import CounterAnimation from 'src/shadcn/components/custom/Animations/CounterAnimation'

type Props = {}

const RecidentialServices = (props: Props) => {

  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [openUserDetailsDailog, setOpenUserDetailsDailog] = useState(false)
  const dispatch = useDispatch()
  const { residentialUserDetails } = useSelector((state: RootState) => getTempDataRootState(state))

  const { data: projectSummary, isLoading: isLoadingSummary } = useFetchData(fetchProjectsSummaryApiRequest);
  const { data: topProjectsByCategory, isLoading: isLoadingCategory } = useFetchData(fetchTopProjectsByCategoryApiRequest);

  console.log({ projectSummary, topProjectsByCategory });



  return (
    <div className='pb-40'>
      <div className='w-full relative '>
        <div className='w-full'>
          <HeroSection />
        </div>

      </div>

      <div className='bg-[#EEEDEC]' style={{ margin: 0 }} >
        <div style={{
          background: `url(${WELCOME_SERVICE_BG})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          margin: "0 "
        }}>
          <div className='space-y-8 pt-16 pb-8'>
            <div className='px-16'>
              <div className='flex items-center space-x-2'> <img src={ANNOTATION_ICON} style={{ width: "20px", height: "20px" }} /> <span className=' font-bold text-3xl'>Residential</span></div>
              <div className='px-[30px]'>Powering businesses with reliable and efficient solar solutions.</div>
            </div>
            <div className='px-4 lg:px-16 mx-auto w-[100vw] lg:w-[80vw]  flex flex-wrap justify-center'>
              {DATA.map((d: any) => {
                return <ServiceTile {...d} />
              })}
            </div>
          </div>

          <div className=" border-t-2 border-dashed border-primary my-2"></div>

          <div className='space-y-8 pt-16 pb-8'>
            <div className='px-16'>
              <div className='flex items-center space-x-2'> <img src={ANNOTATION_ICON} style={{ width: "20px", height: "20px" }} /> <span className=' font-bold text-3xl'>Society</span></div>
              <div className='px-[30px]'>Powering businesses with reliable and efficient solar solutions.</div>
            </div>
            <div className='px-4 lg:px-16 mx-auto w-[100vw] lg:w-[80vw]  flex flex-wrap justify-center'>
              {SOCIETY_DATA.map((d: any) => {
                return <ServiceTile {...d} />
              })}
            </div>
          </div>

          <div className=" border-t-2 border-dashed border-primary my-2"></div>

          <div className='space-y-8 pt-8 pb-8'>
            <div className='px-16'>
              <div className='flex items-center space-x-2'> <img src={ANNOTATION_ICON} style={{ width: "20px", height: "20px" }} /> <span className=' font-bold text-3xl'>How it works?</span></div>
              <div className=' px-8 md:px-[30px]'>A digital and collaborative approach for the best outcomes.</div>
            </div>
            <div className='px-8 md:px-[95px] mx-auto  flex flex-wrap justify-center space-y-8'>
              <div>If you’re interested in selling your solar project, or part rights or leasing your land, click on "Contact Us" and complete the contact form. Once you confirm your email address, we’ll provide you with the details of a Single Point of Contact (SPOC) and a list of required documents. We look forward to working with you to close your requirement of green energy !!!</div>
              <Button onClick={() => navigate("/" + MAIN_ROUTES_CONST.contact_us)} className='w-[250px]'>Contact Us</Button>
            </div>
          </div>


        </div>

        <div style={{
          background: `url(${IMG_COMMERCIAL_SERVICES_REFERENCES_BG})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center"

        }}>
          <div className='space-y-8 pt-8 pb-8'>
            <div className='px-16'>
              <div className='flex items-center space-x-2'> <img src={ANNOTATION_ICON} style={{ width: "20px", height: "20px" }} /> <span className=' font-bold text-3xl'>References</span></div>
              <div className='px-[30px]'>A showcase of successful transactions.</div>
            </div>
            <div className='px-8 md:px-16 flex flex-col items-center space-y-8'>
              <div className='grid grid-cols-2 md:grid-cols-3 lg:grid-cols-6 gap-4 mx-auto' >
                {
                  REFERENCES_ANALYSIS.map(({ count, dataKey, name, annotation }: any) => {
                    if (projectSummary[dataKey]) {
                      count = projectSummary[dataKey]
                    }
                    return <div className='flex flex-col items-center space-y-1 p-4  '>
                      <div className='font-bold text-3xl text-primary'><CounterAnimation from={0} to={count} /> {annotation}</div>
                      <div className='text-center'>{name}</div>
                    </div>
                  })
                }
              </div>
              <div className='grid md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4  mx-auto' >
                {
                  topProjectsByCategory.map(({ headerImage, imagesAttachments, projectCategoryType, projectName, projectSize, units = "MW" }: any) => {
                    let img = headerImage ? headerImage : imagesAttachments[0]
                    if (RESIDENTIAL_AND_SOCIETY_PROJECT_OPTIONS.map((d: optionTypes) => d.value).includes(projectCategoryType)) {
                      return <div className='flex flex-col items-center space-y-2 bg-white rounded-xl overflow-hidden '>
                        <img src={img} style={{ height: "220px", width: "100%" }} />
                        <div className='flex flex-col items-center py-2 '>
                          <span className='font-bold '>{projectSize / 1000} {units}</span>
                          <span>{projectCategoryType}</span>
                        </div>
                      </div>
                    }
                  })
                }
              </div>
              {/* <Button className='w-[150px]'>Learn More</Button> */}
            </div>
          </div>
        </div>
      </div>

    </div>
  )
}
export default RecidentialServices