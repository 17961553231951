import React, { useState } from "react";
import { Button, Card, Table, TableBody, TableCell, TableFooter, TableHeader, TableRow } from "src/shadcn/components/ui";
import { initialPaymentForm, IPaymentForm, ISolarPlanArr, PAYMENT_FORM_FIELDS, paymentFormSchema } from "./modules";
import { catchAsync, checkDecimal, formatIndianNumber, IS_SUCCESS_RESPONSE } from "src/helpers";
import { Check } from "lucide-react";
import HtmlCodeRender from "src/shadcn/components/custom/HtmlCodeRender";
import CustomeDialog from "src/shadcn/components/custom/CustomeDialog";
import RenderFormComponents from "src/shadcn/components/custom/RenderFormComponents";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { useAuth } from "src/modules/Auth/Core";
import QuantityCounter from "src/shadcn/components/custom/QuantityCounter";
import InputFiled from "src/shadcn/components/custom/InputeFiled/InputFiled";
import { useDispatch } from "react-redux";
import { setlastUserRoute, setSelectedService, setShowLoginModule } from "src/redux/reducers/tempDataSlice";
import { MAIN_ROUTES_CONST } from "src/navigations/modules";
import { updateQuotationProjectRequestApi } from "src/services/requests/Projects";

const SolarplanCard = (data: any) => {


  const {
    benefits,
    emiPrice,
    name,
    overAllPrice,
    projectPrice,
    unlockPrice,
    features,
    typicalRate,
    interestSavings,
    id,
    projectDetails,
    plantSize
  } = data


  console.log({ data });


  const [isLoading, setisLoading] = useState(false)
  const [showDialog, setShowDialog] = useState<boolean>(false)
  const navigate = useNavigate()
  const { currentUser } = useAuth()
  const dispatch = useDispatch()


  const formik = useFormik<IPaymentForm>({
    initialValues: initialPaymentForm,
    validationSchema: paymentFormSchema,
    onSubmit: (values, { setSubmitting }) => {
      // Handle form submission here
      //handleEnquiryForm()
    },
  });

  const handleUpdatePeojectDetails = catchAsync(async () => {
    setisLoading(true)
    return await updateQuotationProjectRequestApi(id, {
      projectCost: projectPrice,
      intrestRate: "10.99%",
      interestAmount: emiPrice,
      totalProjectCost: overAllPrice,
      payBack: "72",
      termPlan: "72",
      pricePerKWP: typicalRate,
    })
  }, (result: any) => {
    if (IS_SUCCESS_RESPONSE(result)) {
      setisLoading(false)
    }
  }, () => { setisLoading(false) })

  return (
    <>
      <Card className=" font-sans  hover:bg-[#E0995833] border border-primary border-t-[5px] rounded-[26px] md:w-[413px] md:h-[458px]  p-9 space-y-4">
        <h1 className="text-2xl font-bold text-primary text-center uppercase">{name}</h1>
        <div className="text-[#231D4F] flex flex-col justify-center items-center text-2xl space-x-3 ">
          <div className="font-normal text-nowrap text-center text-sm">Estimated Project Price </div>
          <div className="font-bold text-nowrap text-center">
            ₹{""} {checkDecimal(projectPrice?.toString(), true)}
          </div>
        </div>

        {/* <div className="grid grid-cols-2 ">
          <div className="space-y-1">
            <div className="text-sm font-bold text-primary">Overall Price</div>
            <div className="text-[#231D4F] font-bold">
              ₹ {checkDecimal(overAllPrice?.toString(), true)}
            </div>
          </div>
          <div className="space-y-1">
            <div className="text-sm font-bold text-primary">
              EMI ( 12 Months )
            </div>
            <div className="text-[#231D4F] font-bold">
              ₹ {checkDecimal(emiPrice?.toString(), true)}
            </div>
          </div>
        </div> */}

        <div className="space-y-2">
          {[`Plant Size <b>${plantSize} kW</b>`, ...benefits]?.map((cur: string, index: number) => (
            <div className="flex items-center space-x-4" key={`${cur}-${index}`}>
              <div className="w-[24px] h-[24px] rounded-full bg-[#FF790026] text-primary flex justify-center items-center">
                <Check className="w-4 h-4" strokeWidth={3} />
              </div>
              <div className="text-sm font-semibold">
                <HtmlCodeRender htmlString={cur} />
              </div>
            </div>
          ))}
        </div>

        <Button onClick={() => {
          dispatch(setSelectedService({
            benefits,
            emiPrice,
            name,
            overAllPrice,
            projectPrice,
            unlockPrice,
            features,
            typicalRate,
            interestSavings,
            id,
            ...projectDetails
          }))
          // navigate("/" + MAIN_ROUTES_CONST.order_confirmation + "/" + id )
          setShowDialog(true)
        }} className="rounded-full text-center w-full text-sm font-normal h-[43px]">
          {/* Unlock <span className="font-bold"> ₹ {formatIndianNumber(unlockPrice.toString(),2)} Select</span> in Interest Savings Over Time  */}
          Select
        </Button>

      </Card>

      <CustomeDialog className='' isOpen={showDialog} headerTitle={"Order Details "} isLoading={isLoading} headerTitleClassName="uppercase text-primary font-bold"
        showExtraBtn
        submitBtnTitle="Continue Payment"
        extrabtnTitle="Need Finance"
        extraBtnClick={() => {
          navigate("/" + MAIN_ROUTES_CONST.finance_service)
        }}
        onCancle={() => { setShowDialog(false) }}
        onSubmit={() => {
          handleUpdatePeojectDetails()
          if (!currentUser) {
            dispatch(setShowLoginModule(true))
            dispatch(setlastUserRoute("/" + MAIN_ROUTES_CONST.order_confirmation + "/" + id))
          }
          else navigate("/" + MAIN_ROUTES_CONST.order_confirmation + "/" + id)

        }}
      >
        <div className="w-[300px] md:w-auto grid md:grid-cols-2 gap-4">
          <div className="rounded-lg space-y-2  mb-4 bg-primary-foreground py-4 px-4 border border-primary ">
            <div>
              <p className="text-gray-700 flex justify-between"><span>Plan Type:</span> <span className="font-bold">ECONOMY</span></p>
              <p className="text-gray-700 flex justify-between"><span>Project Price:</span> <span>₹ {checkDecimal(projectPrice, true)}</span></p>
              <p className="text-gray-700 flex justify-between"><span>Overall Price:</span> <span>₹ {checkDecimal(overAllPrice, true)}</span></p>
              <p className="text-gray-700 flex justify-between"><span>EMI (12 Months):</span> <span>₹ {checkDecimal(emiPrice, true)}</span></p>
            </div>
            <div className="mt-4">
              <h3 className="font-semibold">Features:</h3>
              <ul className="list-disc list-inside">
                <li>Age: {features?.age}</li>
                <li>Efficiency: {features?.efficiency}</li>
                <li>Brand Origin: {features?.brandOrigin}</li>
                <li>Subsidy Eligible: {features?.subsidyEligible ? 'Yes' : 'No'}</li>
              </ul>
            </div>
            <div>
              <p className="text-gray-700 mt-2 flex justify-between"><span>Typical Rate:</span> <span>{typicalRate}/kW</span></p>
              <p className="text-gray-700 flex justify-between"><span>Interest Savings Over Time:</span> <span>₹ {interestSavings}</span></p>
            </div>
          </div>
          <div className="flex flex-col space-y-2">
            <div className="font-semibold ">Payment Details </div>
            <Table>
              <TableBody >
                <TableRow>
                  <TableCell className="font-medium text-xs">Product Price</TableCell>
                  <TableCell className="text-right text-xs">₹ {formatIndianNumber(overAllPrice?.toString(), 2)}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className="font-medium text-xs">Quantity </TableCell>
                  <TableCell className="text-right text-xs">1</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className="font-medium text-xs">Tax (18% GST)</TableCell>
                  <TableCell className="text-right text-xs">₹ 0</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className="font-medium text-xs">Service Charge</TableCell>
                  <TableCell className="text-right text-xs">₹ 0</TableCell>
                </TableRow>
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TableCell >Total</TableCell>
                  <TableCell className="text-right">₹ {formatIndianNumber((overAllPrice)?.toString(), 2)}</TableCell>
                </TableRow>
              </TableFooter>
            </Table>
            
          </div>

        </div>
      </CustomeDialog>
      
    </>
  );
};

export default SolarplanCard;
