import { ArrowLeft, ArrowRight } from "lucide-react";
import { useEffect, useMemo, useState } from "react";
import { MdFirstPage, MdLastPage } from "react-icons/md";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { VIDEO } from "src/helpers";
import FilterMain from "src/modules/Marketplace/Filters/FilterMain";
import ProjectResultsLoader from "src/modules/Marketplace/Loaders/ProjectResultsLoader";
import ResultFilter from "src/modules/Marketplace/Result/ResultFilter";
import { getTempDataRootState } from "src/redux/reducers/tempDataSlice";
import { RootState } from "src/redux/store";
import { Separator } from "src/shadcn/components/ui/separator";
import {
  QueryRequestProvider,
  useQueryRequest,
} from "./core/QueryRequestProvider";
import {
  QueryResponseProvider,
  useQueryResponseData,
  useQueryResponseLoading,
  useQueryResponsePagination
} from "./core/QueryResponseProvider";
import Marketplacesheet from "./Marketplacesheet";

type Props = {};

const Main = (props: Props) => {
  const { userLocationDetails } = useSelector((state: RootState) =>
    getTempDataRootState(state)
  );
  const navigate = useNavigate();
  const element = document.getElementById("projects-list");
  console.log({ userLocationDetails });

  const apiResponse = useQueryResponseData();
  console.log({ apiResponse });

  const projects = useMemo(() => apiResponse, [apiResponse]);
  const isLoading = useQueryResponseLoading();
  const paggination = useQueryResponsePagination();
  const { updateState } = useQueryRequest();

  const [open, setopen] = useState(false);

  useEffect(() => {
    if (userLocationDetails?.address) {
      let spliteAddr = userLocationDetails?.address.split(",").reverse();
      console.log({ spliteAddr });

      if (
        !spliteAddr.some(
          (d: any) =>
            d.toLowerCase().includes("maharashtra") ||
            d.toLowerCase().includes("uttar pradesh")
        )
      ) {
        navigate("/");
      }
    }
    return () => {};
  }, [userLocationDetails]);

  const handleFilters = (e: any) => {
    console.log({ filter: e });
    delete e["price"];
    // delete e["consumerType"]
    

    updateState({
      page: 1,
      items_per_page: 10,
      filter:e,

    });
  };

  const totalProjectSize = useMemo(() => {
    if (projects && Array.isArray(projects) && projects.length > 0) {
      return (
        projects.reduce(
          (acc: any, curr: any) => (acc += Number(curr.projectSize)),
          0
        ) / 1000
      );
    }
    return 0;
  }, [projects]);

  const handleScroll = () => {
    if (element) {
      // 👇 Will scroll smoothly to the top of the next section
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div className="w-full mb-32 md:mb-0 lg:mb-32">
      {/* <div className='w-full h-[300px] overflow-hidden' style={{ backgroundImage: "url(" + toAbsoluteUrl("/media/jpg/marketExplorerBanner.png") + ")", backgroundSize: "cover" }} >
        <div className='flex flex-col justify-center items-center h-[100%] space-y-2'>
          <div className='text-white font-bold md:text-3xl text-2xl' >
            <div className='text-center'>Discover, Compare, and</div>
            <div className='text-center'>Choose Solar Project Experience</div>
          </div>
          <div className='text-white text-center ' >Explore a wide range of solar projects <br /> tailored to meet your solar projects needs.</div>
        </div>
      </div> */}
      <div className="mb-8 h-auto">
        <video autoPlay loop muted style={{ width: "1580px" }}>
          <source src={VIDEO} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
      {/* <div className='flex flex-col items-center py-8 space-y-4'>
        <div className='text-center  text-3xl '>
          Find <span className='font-bold'>Solar Projects </span> of your choice on
        </div>
        <img src={toAbsoluteUrl("/media/svg/Electricity_PM.svg")} style={{ height: "80px" }} className='mb-4' />
      </div> */}

      <div className="w-full flex flex-col gap-6">
        <div className="hidden lg:block md:hidden xl:block ">
          <FilterMain onChange={handleFilters} />
        </div>

        <div className="lg:hidden xl:hidden flex ml-4">
          <Marketplacesheet
            children={
              <FilterMain
                onChange={handleFilters}
                open={open}
                setopen={setopen}
              />
            }
            open={open}
            setopen={setopen}
          />
        </div>

        <div
          id="projects-list"
          className="md:col-span-3 w-full md:w-[80vw] mx-auto  p-2 space-y-2 "
        >
          <div className="md:flex md:justify-between md:items-center px-4 md:px-0">
            <div className="uppercase text-primary font-bold">Projects</div>
            <div className="md:flex md:space-x-2">
              <div className="flex text-sm">
                <span className="text-gray-400">Total : </span>
                <span>{projects.length}</span>
              </div>
              <Separator orientation="vertical" />
              <div className="flex text-sm">
                <span className="text-gray-400">Total Project Size : </span>
                <span>{totalProjectSize} MW</span>
              </div>
            </div>
          </div>
          {isLoading ? (
            <div className="flex flex-col space-y-2">
              {[...new Array(3)].map((_) => {
                return <ProjectResultsLoader />;
              })}
            </div>
          ) : (
            <ResultFilter projects={projects} loading={isLoading} />
          )}
        </div>

        <div className="flex items-center justify-center">
          <div className="flex items-center space-x-2">
            <button
              className="border p-2 rounded-md"
              onClick={() => {
                updateState({
                  page: 1,
                  items_per_page: 10,
                });
                handleScroll();
              }}
              disabled={paggination?.page === 1}
            >
              <MdFirstPage />
            </button>
            <button
              className="border p-2 rounded-md"
              onClick={() => {
                updateState({
                  page: paggination?.page !== 1 ? paggination?.page - 1 : 1,
                  items_per_page: 10,
                });
                handleScroll();
              }}
              disabled={paggination?.page === 1}
            >
              <ArrowLeft />
            </button>
            <span>
              Page {paggination?.page} of {paggination?.total_pages}
            </span>
            <button
              className="border p-2 rounded-md"
              onClick={() => {
                updateState({
                  page: Number(paggination?.page) + 1,
                  items_per_page: 10,
                });
                handleScroll();
              }}
              disabled={
                paggination.page && paggination?.last_page
                  ? Number(paggination.page) === Number(paggination?.last_page)
                  : true
              }
            >
              <ArrowRight />
            </button>
            <button
              className="border p-2 rounded-md"
              onClick={() => {
                updateState({
                  page: Number(paggination?.last_page),
                  items_per_page: 10,
                });
                handleScroll();
              }}
              disabled={
                paggination.page && paggination?.last_page
                  ? Number(paggination.page) === Number(paggination?.last_page)
                  : true
              }
            >
              <MdLastPage />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const MarketplaceMain = () => {
  return (
    <QueryRequestProvider>
      <QueryResponseProvider>
        <Main />
      </QueryResponseProvider>
    </QueryRequestProvider>
  );
};

export default MarketplaceMain;
