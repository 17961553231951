import React from 'react'
import { LOAN_STEPS } from './Constants'

type Props = {}

const WorkingProcess = (props: Props) => {
    return (
        <section className="bg-white py-12 border-2  mt-20 rounded-[25px]">
            <div className="container mx-auto px-4 text-center">
            <p className="text-primary font-bold text-xl mb-4">--- HOW IT WORKS ---</p>
                <h2 className="text-3xl font-bold text-gray-800 mb-4">
                    Our Working Process
                </h2>
                
                <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
                    {LOAN_STEPS.map((step) => (
                        <div key={step.id} className="text-center items-center justify-center flex flex-col">
                            <img
                                src={step.image}
                                alt={step.title}
                                className="w-52 h-32"
                            />

                            <h3 className="text-lg font-semibold  my-4">
                                {step.title}
                            </h3>
                            <p className="text-sm ">{step.description}</p>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    )
}

export default WorkingProcess