import React from 'react'
import CostomerJourneyMain from 'src/modules/ReStructure/CustomerJorney/CostomerJourneyMain'

const CustomerJourny = () => {
  return (
    <div className='w-full mb-24 md:px-20 py-10'>
        <CostomerJourneyMain/>
    </div>
  )
}

export default CustomerJourny