import Service2Details from 'src/modules/SubServices/Service2/Service2Details'
 
type Props = {} 
 
const Service2 = (props: Props) => { 
    return ( 
        <div> 
            <Service2Details /> 
        </div> 
    ) 
} 
 
export default Service2 
