import React from 'react'
import { FINANCE_ANALYSIS, REFERENCES_ANALYSIS } from '../ReStructure/Services/Component/modules'
import { IMG_CROWDFUNDING_BANNER } from 'src/helpers'
import CounterAnimation from 'src/shadcn/components/custom/Animations/CounterAnimation'

type Props = {}

const BottomSection = (props: Props) => {
  return (
    <div  className='rounded-[25px]'
    style={{
              background: `url(${IMG_CROWDFUNDING_BANNER})`,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center"
    
            }}>
              <div className='p-20 mt-20 px-20'>
                <div className='mt-20'>
                <div className='text-white text-5xl font-bold mb-2'>The right decision,</div>
                <div className='text-primary text-4xl'>at the right time.</div>
                </div>
                  <div className='flex space-x-36 pt-20 mx-10' >
                    {
                      FINANCE_ANALYSIS.map(({ count, dataKey, name, annotation }: any) => {
                        // if (projectSummary[dataKey]) {
                        //   count = projectSummary[dataKey]
                        // }
                        return <div className='flex flex-col items-center  space-y-1 p-4  '>
                          <div className='font-bold text-4xl text-white'><CounterAnimation from={0} to={count} />{annotation}</div>
                          <div className='font-bold text-2xl text-center text-white'>{name}</div>
                        </div>
                      })
                    }
                  </div>
                
                </div>
              
            </div>
  )
}

export default BottomSection