
import { EMAIL_REGEX, ENQUIRY_SERVICE_TYPES, ENQUIRY_TYPES_INSTREST, FormField, NUMBER_REGEX } from 'src/helpers';
import * as Yup from 'yup';

export interface IEnquiryForm4 {

  description: string;
  // service_type: string;
  type:string
  project_id:string
  userid:string
  project_type:string
}


export const initialEnquiryForm4: IEnquiryForm4 = {

  // service_type: "",
  description: "",
  type:"",
  project_id:"",
  userid:"",
  project_type:""
};

export const enquiryForm4Schema = Yup.object().shape({
  // // whoYouAre: Yup.string().required('Who you are is required'),
  // firstName: Yup.string().required('First name is required'),
  type: Yup.string().required('Type of interest is required.'),
  // phone: Yup.string().required('Phone number is required').matches(/^[0-9]{10}$/, 'Phone number must be 10 digits'),
  // email: Yup.string().required('Email is required').email('Email must be a valid email address'),
  description: Yup.string().required('Description is required'),
  // service_type: Yup.string().required('Service type is required')
});


export const ENQUIRY_FORM_3_FIELDS: FormField[] = [

  // {
  //   label: 'First Name',
  //   dataKey: 'firstName',
  //   componentType: 'input',
  //   componentProps: {
  //     type: "text",
  //     placeholder: 'First Name',
  //     maxLength: 50,
  //     required: true,
  //     error_className: "text-white"
  //   },
  // },
  // {
  //   label: 'Last Name',
  //   dataKey: 'lastName',
  //   componentType: 'input',
  //   componentProps: {
  //     type: "text",
  //     placeholder: 'Last Name',
  //     maxLength: 50,
  //     required: true,
  //     error_className: "text-white"
  //   },
  // },
  // {
  //   label: 'Phone',
  //   dataKey: 'phone',
  //   componentType: 'input',
  //   componentProps: {
  //     type: "text",
  //     placeholder: 'Phone Number',
  //     maxLength: 10,
  //     regex: NUMBER_REGEX,
  //     required: true,
  //     error_className: "text-white"
  //   },
  // },
  // {
  //   label: 'Email',
  //   dataKey: 'email',
  //   componentType: 'input',
  //   componentProps: {
  //     type: "email",
  //     placeholder: 'Email',
  //     maxLength: 150,
  //     required: true,
  //     regex: EMAIL_REGEX,
  //     error_className: "text-white"
  //   },
  // },

  // {
  //   label: 'Service Type',
  //   dataKey: 'service_type',
  //   componentType: 'options',
  //   componentProps: {
  //     options: ENQUIRY_SERVICE_TYPES,
  //     required: true,
  //     error_className: "text-white"
  //   },
  // },
  {
    label: 'Type Of Interest',
    dataKey: 'type',
    componentType: 'options',
    componentProps: {
      options: ENQUIRY_TYPES_INSTREST,
      required: true,
      error_className: "text-white"
    },
  },
  {
    label: 'Description',
    dataKey: 'description',
    componentType: 'textarea',
    componentProps: {
      placeholder: 'Description',
      required: true,
      maxLength: 250,
      error_className: "text-white"
    },
  },
];



export const ENQUIRY_FORM_4_FIELDS_cp: FormField[] = [

  // {
  //   label: 'First Name',
  //   dataKey: 'firstName',
  //   componentType: 'input',
  //   componentProps: {
  //     type: "text",
  //     placeholder: 'First Name',
  //     maxLength: 50,
  //     required: true,
      
  //   },
  // },
  // {
  //   label: 'Last Name',
  //   dataKey: 'lastName',
  //   componentType: 'input',
  //   componentProps: {
  //     type: "text",
  //     placeholder: 'Last Name',
  //     maxLength: 50,
  //     required: true,
      
  //   },
  // },
  // {
  //   label: 'Phone',
  //   dataKey: 'phone',
  //   componentType: 'input',
  //   componentProps: {
  //     type: "text",
  //     placeholder: 'Phone Number',
  //     maxLength: 10,
  //     regex: NUMBER_REGEX,
  //     required: true,
      
  //   },
  // },
  // {
  //   label: 'Email',
  //   dataKey: 'email',
  //   componentType: 'input',
  //   componentProps: {
  //     type: "email",
  //     placeholder: 'Email',
  //     maxLength: 150,
  //     required: true,
  //     regex: EMAIL_REGEX,
      
  //   },
  // },

  // {
  //   label: 'Service Type',
  //   dataKey: 'service_type',
  //   componentType: 'options',
  //   componentProps: {
  //     options: ENQUIRY_SERVICE_TYPES,
  //     required: true,
      
  //   },
  // },
  {
    label: 'Type Of Interest',
    dataKey: 'type',
    componentType: 'options',
    componentProps: {
      options: ENQUIRY_TYPES_INSTREST,
      required: true,
      
    },
  },
  {
    label: 'Description',
    dataKey: 'description',
    componentType: 'textarea',
    componentProps: {
      placeholder: 'Description',
      required: true,
      maxLength: 250,
      
    },
  },
];