/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useContext, useEffect, useMemo, useState } from 'react'
import { useQuery } from 'react-query'
import {
  ERROR_CODES,
  PaginationState,
  USER_ROLES,
  WithChildren,
  createResponseContext,
  initialQueryResponse,
  initialQueryState,
  stringifyRequestQuery
} from 'src/helpers'
import { useQueryRequest } from './QueryRequestProvider'
import { allManufacturersRequest } from 'src/services/requests/Manufacturers'
import { fetchAllProductsApiRequest } from 'src/services/requests/Products'
import { fetchOrdersByUserIDApiRequest } from 'src/services/requests/Order'
import { useAuth } from 'src/modules/Auth/Core'


const QueryResponseContext = createResponseContext<any>(initialQueryResponse)
const QueryResponseProvider: FC<WithChildren> = ({ children, props, type, postdata }) => {

  const { state, updateState } = useQueryRequest()
  const [query, setQuery] = useState<string>(stringifyRequestQuery(state))
  const { currentUser } = useAuth()
  const updatedQuery = useMemo(() => stringifyRequestQuery(state), [state])

  // useEffect(() => {
  //   updateState({
  //     filter: {
  //       user_id: "6703cf83ee61260c92aba32b"
  //     }
  //   })
  //   return () => { }
  // }, [])


  useEffect(() => {
    if (query !== updatedQuery) {
      setQuery(updatedQuery);
    }
  }, [updatedQuery])

  const {
    isFetching,
    refetch,
    data: response,
  } = useQuery(
    `ORDERS-GETALL-BY-USER-${query}`,
    async () => {
      return currentUser?.id ? await fetchOrdersByUserIDApiRequest(currentUser?.id) : undefined
    },
    {
      cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false,
      refetchOnMount: false,
      // refetchOnReconnect: false,
      // retry: false,
      //  staleTime: 5000,
    }
  )

  let responseData: any = useMemo(() => response, [response])

  let isError = useMemo(() => ERROR_CODES.includes(responseData?.code) ? true : false, [responseData])
  let message = useMemo(() => ERROR_CODES.includes(responseData?.code) ? responseData?.message : '', [responseData])

  return (
    <QueryResponseContext.Provider value={{ isLoading: isFetching, refetch, query, isError, response, message }}>
      {children}
    </QueryResponseContext.Provider>
  )
}

const useQueryResponse = () => useContext(QueryResponseContext)

const useQueryResponseData = () => {
  const { response } = useQueryResponse()
  if (!response) {
    return []
  }

  return response?.data || []
}

const useQueryResponsePagination = () => {
  const defaultPaginationState: PaginationState = {
    links: [],
    ...initialQueryState,
  }

  const { response } = useQueryResponse()

  if (!response || !response?.payload || !response?.payload?.pagination) {
    return defaultPaginationState
  }

  return response?.payload?.pagination
}

const useQueryResponseLoading = (): boolean => {
  const { isLoading } = useQueryResponse()
  return isLoading
}

export {
  QueryResponseProvider,
  useQueryResponse,
  useQueryResponseData, useQueryResponseLoading, useQueryResponsePagination
}

