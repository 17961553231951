import React from "react";
import { useNavigate } from "react-router-dom";

import { REFER_AND_EARN_IMAGE_URL, toAbsoluteUrl } from "src/helpers";
import { MAIN_ROUTES_CONST } from "src/navigations/modules";
import { Button, Card } from "src/shadcn/components/ui";

const ReferAndEarn = () => {
  const ReferAndEarnImg = REFER_AND_EARN_IMAGE_URL;
  const navigate = useNavigate()
  return (
    <div className="w-full flex justify-center items-center font-sans">
      <Card className="w-[80%] lg:w-[75%] xl:w-[80%] h-[400px] xl:h-[157px] bg-white grid  xl:grid-cols-3 gap-x-3 border-2 rounded-[25px]">
        <div className="flex justify-center items-center">
          <img src={ReferAndEarnImg} alt="ReferAndEarn" />
        </div>
        <div className="w-full flex flex-col justify-center md:items-start space-y-2">
          <h1 className="w-full text-black text-3xl font-bold text-left flex justify-center">
           <span>Refer </span> <span className="text-primary ml-2">& Earn</span>
          </h1>
          <div className="w-full text-sm font-normal text-center align-middle px-8">
            Help others go solar and earn exciting rewards! Refer <br /> friends
            and family to install solar panels, and get up to ₹ 16,000 for each successful referral.
          </div>
        </div>
        <div className="flex justify-center items-center">
          <Button onClick={() => navigate("/" + MAIN_ROUTES_CONST.refer_now)} className="bg-[#686562] hover:bg-[#686562] w-[195px] h-[51px] border p-[10px] text-[20px] font-bold">
            Refer Now
          </Button>
        </div>
      </Card>
    </div>
  );
};

export default ReferAndEarn;
