import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { toast } from 'sonner'
import { catchAsync, checkDecimal, IS_SUCCESS_RESPONSE, USER_ROLES } from 'src/helpers'
import { MAIN_ROUTES_CONST } from 'src/navigations/modules'
import { addOrderItem } from 'src/redux/reducers/orderReducer'
import { addItemToCartApiRequest, removeItemFromCartApiRequest, updateCartItemsQuantityApiRequest } from 'src/services/requests/carts'
import { Button } from 'src/shadcn/components/ui'

import { fetchCartItemsAction } from 'src/redux/reducers/cartReducer'
import { useAuth } from '../Auth/Core'
import { ScrollArea } from '@radix-ui/react-scroll-area'
import QuantityCounter from 'src/shadcn/components/custom/QuantityCounter'


type Props = {}

const CartItem = (d: any) => {

  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { currentUser } = useAuth()
  const [addingToCart, setAddingToCart] = useState<boolean>(false)

  const handelRemoveCartItem = catchAsync(async () => {
    // setAddingToCart(true)
    return await removeItemFromCartApiRequest(d._id)
  }, (result: any) => {
    if (IS_SUCCESS_RESPONSE(result)) {
      toast.success("Product removed from cart")
      dispatch(fetchCartItemsAction())
    }
    setAddingToCart(false)
  }, () => { setAddingToCart(false) })

  const handelUpdateProductQuantity = catchAsync(async (quantity: string) => {
    setAddingToCart(true)
    return await updateCartItemsQuantityApiRequest(d._id, {
      quantity: quantity
    })
  }, (result: any) => {
    if (IS_SUCCESS_RESPONSE(result)) {
      toast.success("Quantity Updated")
      dispatch(fetchCartItemsAction())
    }
    setAddingToCart(false)
  }, () => { setAddingToCart(false) })

  return (
    <div key={`${d?._id}`} className='flex flex-col space-y-2 rounded-lg border border-gray-300 hover:border-none hover:shadow-xl overflow-hidden transition duration-300'>
      <button onClick={() => navigate("/" + MAIN_ROUTES_CONST.products_details + "/" + d?.product?._id)} className='row-span-7 px-4 p-4'>
        <div className='flex flex-col space-y-1'>
          <div className=''>
            <img src={d?.product?.productImage} className='rounded-xl' style={{ width: "100%", height: "180px" }} />
          </div>
          <div className='flex justify-center items-center h-100 w-100  text-center text-sm text-primary  font-semibold'>
            {d?.product?.name}
          </div>
          <ScrollArea className='flex justify-center items-start h-100 w-100 h-[50px]  text-xs text-center '>
            {d?.product?.description}

          </ScrollArea>
          <div className=' flex items-center justify-between'>
            <div className='flex flex-col  space-y-2 '>
              <span className='text-sm text-center text-gray-400'>Quantity</span>
              <span className='text-sm text-center text-primary font-semibold'># {checkDecimal(d?.product?.total_quantity, true)}</span>
            </div>
            <div className='flex flex-col  space-y-2'>
              <span className='text-sm text-center text-gray-400'>Price </span>
              <span className='text-sm text-center text-primary font-semibold'>₹ {checkDecimal(d?.product?.price, true)}/piece</span>
            </div>
          </div>
        </div>
      </button>
      <div className='flex space-x-2 p-4'>
        <Button onClick={() => { handelRemoveCartItem() }} variant={'outlineDestructive'} className='flex-1' >Remove</Button>
        <QuantityCounter isLoading={addingToCart} value={d?.quantity} onChange={(e: any) => { handelUpdateProductQuantity(e) }} />
      </div>
    </div>
  )
}

export default CartItem