import React, { useState, createContext, FC, useContext, ReactNode, useMemo, useRef } from 'react';

import { ContextProps, initialContext } from './modules';
import { optionTypes } from 'src/helpers';

const MainContext = createContext<ContextProps>(initialContext)

const MainContextProvider: FC<{
    children: ReactNode
}> = ({ children }) => {

    const [location, setlocation] = useState<any>(undefined)
    const [totalPolygonArea, setTotalPolygonArea] = useState<any>(undefined)
    const [totalObstacleArea, settotalObstacleArea] = useState<any>(undefined)
    const [totalPanelArea, setTotalPanelArea] = useState<any>(undefined)
    const [totalObstacleCount, settotalObstacleCount] = useState<any>(undefined)
    const [totalPanelsCount, setTotalPanelsCount] = useState<any>(undefined)
    const [perPanelEnergy, setperPanelEnergy] = useState<any>(undefined)
    const mapLocation = useMemo(() => location, [location])
    const totalSelectedArea = useMemo(() => totalPolygonArea, [totalPolygonArea])
    const totalObstaclesCount = useMemo(() => totalObstacleCount, [totalObstacleCount])
    const totalMapPanelCounts = useMemo(() => totalPanelsCount, [totalPanelsCount])
    const totalMapObstacleArea = useMemo(() => totalObstacleArea, [totalObstacleArea])
    const totalMapPanelArea = useMemo(() => totalPanelArea, [totalPanelArea])
    const perMapPanelEnergy = useMemo(() => perPanelEnergy, [perPanelEnergy])

    const [projectId, setProjectId] = useState<any>(undefined)


    const [submitDetail, setSubmitDetail] = useState(false)
    const quotationRef = useRef<() => void | null>(null)

    const setQuotation = (quotationMethod: (() => void) | null) => {
        quotationRef.current = quotationMethod
    }

    const callQuotation = () => {
        if (submitDetail && quotationRef.current) {
            quotationRef.current()
        }
    }

    const setMapLocation = (data: any) => {
        setlocation(data)
    }

    const setTotalSelectedArea = (data: any) => {
        setTotalPolygonArea(data)
    }

    const setTotalMapObstacleArea = (data: any) => {
        settotalObstacleArea(data)
    }

    const setTotalMapPanelArea = (data: any) => {
        setTotalPanelArea(data)
    }

    const settotalObstaclesCount = (data: any) => {
        settotalObstacleCount(data)
    }

    const setTotalMapPanelCounts = (data: any) => {
        setTotalPanelsCount(data)
    }

    const setMapPerPanelEnergy = (data: any) => {
        setperPanelEnergy(data)
    }

    const setSubmitDetails = (data: any) => {
        setSubmitDetail(data)
    }

    const setProId = (data : any) =>{
        setProjectId(data)
    }


    return (
        <MainContext.Provider value={{ mapLocation, totalSelectedArea, totalMapObstacleArea, totalMapPanelArea, totalObstaclesCount, totalMapPanelCounts, perMapPanelEnergy, setMapLocation, setTotalSelectedArea, setTotalMapObstacleArea, setTotalMapPanelArea, settotalObstaclesCount, setTotalMapPanelCounts, setMapPerPanelEnergy, setSubmitDetails, submitDetail, setQuotation, callQuotation, projectId, setProId }}>
            {children}
        </MainContext.Provider>
    )
}

const useMainContext = () => useContext(MainContext)
export { MainContextProvider, useMainContext }