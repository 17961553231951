import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import ProductInformation from 'src/modules/Products/ProductInformation'
import { fetchCartItemsAction } from 'src/redux/reducers/cartReducer'

type Props = {}

const ProductsPageMain = (props: Props) => {

    const dispatch = useDispatch()

    useEffect(() => {
         dispatch(fetchCartItemsAction())
        return () => { }
    }, [])


    return (
        <div className=''>
            <div className='space-y-2 py-2'>
                <ProductInformation />
                
                {/* <ListMain /> */}
            </div>
        </div>
    )
}

export default ProductsPageMain