import { AxiosResponse } from "axios";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "sonner";
import { catchAsync, successTheme } from "src/helpers";
import { createNewServiceEnquiryRequestRequestApi, createServiceEnquiryRequestRequestApi } from "src/services/requests/ServiceEnquiryRequests";
import CustomeDialog from "src/shadcn/components/custom/CustomeDialog";
import RenderFormComponents from "src/shadcn/components/custom/RenderFormComponents";
import RoundedButton from "src/shadcn/components/custom/RoundedButton";
import {
  ENQUIRY_FORM_3_FIELDS,
  ENQUIRY_FORM_3_FIELDS_cp,
  enquiryForm3Schema,
  IEnquiryForm3,
  initialEnquiryForm3,
} from "./EnquiryForm3/modules";
import {
  ENQUIRY_FORM_4_FIELDS_cp,
  enquiryForm4Schema,
  IEnquiryForm4,
  initialEnquiryForm4,
} from "./EnquiryForm4/modules";

type Props = {
  initValues?: {};
  openState: boolean;
  isOpenState: (e: any) => void;
};

const EnquiryRequestDialog = ({
  initValues,
  openState,
  isOpenState,
}: Props) => {
  const [isLoading, setisLoading] = useState(false);
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const navigate = useNavigate();

  const formik = useFormik<IEnquiryForm4>({
    initialValues: initialEnquiryForm4,
    validationSchema: enquiryForm4Schema,
    onSubmit: (values, { setSubmitting }) => {
      // Handle form submission here
      handleEnquiryForm();
    },
  });

  useEffect(() => {
    if (initValues) {
      Object.keys(initialEnquiryForm4).map((d: any) => {
        formik.setFieldValue(d, initValues[d as keyof Object]);
      });
    }
    return () => {};
  }, [initValues]);

  const handleEnquiryForm = catchAsync(
    async () => {
      setisLoading(true);
      return await createNewServiceEnquiryRequestRequestApi(formik.values);
    },
    (result: AxiosResponse) => {
      if (result.status == 201) {
        toast.success("Request sent successful", { style: successTheme });
        formik.resetForm();
        // navigate(-1)
        setShowDialog(true);
        isOpenState(false);
      }
      setisLoading(false);
    },
    () => {
      setisLoading(false);
    }
  );

  console.log({ error: formik.errors, values: formik.values });

  return (
    <div className="space-y-2 ">
      <form className="space-y-2 " onSubmit={formik.handleSubmit}>
        <CustomeDialog
          className=""
          isOpen={openState}
          headerTitle={"Enquiry Request"}
          isLoading={isLoading}
          headerTitleClassName="uppercase text-primary font-bold"
          onCancle={() => {
            isOpenState(false);
          }}
          onSubmit={() => formik.handleSubmit()}
        >
          <div className="grid md:grid-cols-2 gap-4 pb-4 overflow-y-scroll md:overflow-y-hidden">
            {ENQUIRY_FORM_4_FIELDS_cp.map((field: any) => {
              return (
                <RenderFormComponents
                  key={`existing-enquiry-form-${field.dataKey}`}
                  formik={formik}
                  value={formik.values[field.dataKey as keyof object]}
                  {...field}
                  onChange={(e: any) => formik.setFieldValue(field.dataKey, e)}
                />
              );
            })}
          </div>
        </CustomeDialog>
      </form>
      <CustomeDialog
        isOpen={showDialog}
        headerTitle={"Request Submited"}
        hideDefaultCancle
        onSubmit={() => {
          setShowDialog(false);
        }}
      >
        <p className="pb-4">
          Thank You for showing your interest, our team will contact you soon.
        </p>
      </CustomeDialog>
    </div>
  );
};
export default EnquiryRequestDialog;
