// import React from "react";
// import { toAbsoluteUrl } from "src/helpers";
// import Newsletter from "./Newsletter";
// import FooterLink from "./FooterLink";

// const FooterMain_R = () => {
//   const footerBackgroundImg = toAbsoluteUrl(
//     "/media/svg/image/FooterBackground.svg"
//   );
//   const wing_Mill_Img = toAbsoluteUrl("/media/svg/image/windmill_img.svg");
//   return (
//     <div className=" flex flex-col justify-center items-center">
//       <div
//         className="w-[80%] "
//         style={{ backgroundImage: `url(${footerBackgroundImg})` }}
//       >
//         <div className="   grid grid-cols-3 gap-x-4 ">
//           <FooterLink />
//           <Newsletter />
//           <div className="flex justify-end items-center">
//             <img src={wing_Mill_Img} />
//           </div>
//         </div>
//       </div>
//       <div className="h-[50px] bg-[#635555]  flex justify-center items-center text-xs w-full text-white">
//         © 2024 Orange current technologies Pvt. Ltd | All rights reserved |
//         Terms and conditions | Data Privacy
//       </div>
//     </div>
//   );
// };

// export default FooterMain_R;

import { Button } from "src/shadcn/components/ui/button";
import { Input } from "src/shadcn/components/ui/input";
import { MoveUpRight } from "lucide-react";
import React, { useMemo } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { MAIN_ROUTES_CONST } from "src/navigations/modules";
import ReferAndEarn from "./ReferAndEarn/ReferAndEarn";
import Newsletter from "./Newsletter";

type Props = {};

const FooterMain_R = (props: Props) => {

  const { pathname } = useLocation()

  const isShowReferAndEarn = useMemo(() => {
    return ![MAIN_ROUTES_CONST.about_us, MAIN_ROUTES_CONST.our_team, MAIN_ROUTES_CONST.contact_us, MAIN_ROUTES_CONST.refer_now, MAIN_ROUTES_CONST.careers, MAIN_ROUTES_CONST.mission_and_vision,MAIN_ROUTES_CONST.area_calculator,MAIN_ROUTES_CONST.registration].map((d: any) => "/" + d).includes(pathname)

  }, [pathname])

  return (
    <div className="w-full relative">
      <div className="min-h-[500px] md:min-h-[360px] bg-black  flex justify-center items-center text-sm z-0 pt-44 xl:pt-0">
        <div className="grid xl:grid-cols-4 gap-4 max-w-[80vw] mt-16">

          <div className="col-span-3">
            <div className="grid grid-flow-col grid-cols-3 gap-4">
              <div className="space-y-4 ">
                <div className="uppercase font-normal text-sm text-primary  md:mt-0">
                  Navigation
                </div>
                <div>
                  <Link to={MAIN_ROUTES_CONST.welcome}>
                    <div className="text-white">Home</div>
                  </Link>
                </div>
                <div>
                  <Link to={MAIN_ROUTES_CONST.marketplace}>
                    <div className="text-white">Explore Market</div>
                  </Link>
                </div>
                <div>
                  <Link to={MAIN_ROUTES_CONST.commercial_services}>
                    <div className="text-white">Commercial & Industrial Services</div>
                  </Link>
                </div>
                <div>
                  <Link to={MAIN_ROUTES_CONST.residential_services}>
                    <div className="text-white">Residential Services</div>
                  </Link>
                </div>
                <div>
                  <Link to={MAIN_ROUTES_CONST.careers}>
                    <div className="text-white">Career</div>
                  </Link>
                </div>

                {/* <div className="text-white">Products</div> */}
              </div>
              <div className="space-y-4 md:flex md:flex-col ">
                <div className="uppercase font-normal text-sm text-primary">
                  About Us
                </div>
                <div>
                  <Link to={MAIN_ROUTES_CONST.about_us}>
                    <div className="text-white">About Us</div>
                  </Link>
                </div>
                <div>
                  {" "}
                  <Link to={MAIN_ROUTES_CONST.contact_us}>
                    <div className="text-white">Contact us</div>
                  </Link>
                </div>
                <div>
                  <Link to={MAIN_ROUTES_CONST.faq}>
                    <div className="text-white">FAQs</div>
                  </Link>
                </div>
                {/* <div className="text-white">User Manual</div> */}
                <div>
                  <Link to={MAIN_ROUTES_CONST.refer_now}>
                    <div className="text-white">Refer Now</div>
                  </Link>

                </div>
              </div>
              <div className="space-y-4 md:flex md:flex-col ">
                <div className="uppercase font-normal text-sm text-primary">
                  Policy
                </div>
                <div>
                  <Link to={MAIN_ROUTES_CONST.termsAndConditions}>
                    <div className="text-white">Terms & Conditions</div>
                  </Link>
                </div>
                <div>
                  <Link to={MAIN_ROUTES_CONST.dataPrivacy}>
                    <div className="text-white">Privacy Policy</div>
                  </Link>
                </div>
              </div>
            </div>
          </div>

          <div className="col-span-1 w-full">
            <Newsletter />
          </div>

        </div>
      </div>
      <div className="h-[50px] bg-white  md:flex md:justify-center md:items-center text-xs px-5">
        <span> © 2024  Orange current technologies Pvt. Ltd | All rights reserved |{" "} </span>
        <Link to={"/" + MAIN_ROUTES_CONST.termsAndConditions}><span className="text-[#1E4AE9]">Terms And Conditions</span> |{" "}</Link>
        <Link to={"/" + MAIN_ROUTES_CONST.dataPrivacy}> <span className="text-[#1E4AE9]">Data Privacy</span></Link>
      </div>
      {isShowReferAndEarn && <div className="w-full absolute xl:-top-[20%] md:-top-[30%] lg:-top-[30%] -top-[25%]  ">
        <ReferAndEarn />
      </div>}

    </div>
  );
};

export default FooterMain_R;
