import React from "react";
import EnquiryHistory from "src/modules/History/EnquiryHistory";

const EnquiryHistoryMain = () => {
  return (
    <div className="w-full relative mt-24 mb-40">
      <EnquiryHistory />
    </div>
  );
};

export default EnquiryHistoryMain;
