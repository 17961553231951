import { useEffect, useState } from 'react';
import { catchAsync, IS_SUCCESS_RESPONSE } from 'src/helpers';
import { useAuth } from 'src/modules/Auth/Core';
import ProjectResultsLoader from 'src/modules/Marketplace/Loaders/ProjectResultsLoader';
import ResultFilter from 'src/modules/Marketplace/Result/ResultFilter';
import { getWishlistApiRequest } from 'src/services/requests/Wishlist';


function MyWishlist() {
    const { currentUser } = useAuth();
    

    const [wishlist, setWishlist] = useState<any>(null)
    const [isLoading, setisLoading] = useState<boolean>(false)

    console.log(currentUser?.id, 'iddddddddddddddddd');


    useEffect(() => {
        console.log('use effect');
        
        if (currentUser?.id) {
            console.log('inside ifffff');
            getAllWishlist()
        }
       
    },[])

    const getAllWishlist = catchAsync(async () => {
        console.log('here');
        setisLoading(true)
        return currentUser?.id && await getWishlistApiRequest(currentUser?.id)
    }, (result: any) => {
        if (IS_SUCCESS_RESPONSE(result)) {
            setWishlist(result.data)
            console.log(result.data, ".....")
        }
        setisLoading(false)
    }, () => { setisLoading(false) })





    return (
        <div>
            <div className='relative mb-40'>
                <div className=' bg-gray-100 rounded-b-3xl  p-16'>
                {isLoading ? (
            <div className="flex flex-col space-y-2">
              {[...new Array(3)].map((_) => {
                return <ProjectResultsLoader />;
              })}
            </div>
          ) : (
            <ResultFilter projects={wishlist} loading={isLoading} />
          )}
                </div>
            </div>
        </div>
    )
}

export default MyWishlist