import React from 'react' 
import LandLeaseDetails from 'src/modules/SubServices/LandLease/LandLeaseDetails' 
import Service1Details from 'src/modules/SubServices/Service1/Service1Details'
 
type Props = {} 
 
const Service1 = (props: Props) => { 
    return ( 
        <div> 
            <Service1Details /> 
        </div> 
    ) 
} 
 
export default Service1 
