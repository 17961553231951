import { convertToCamelCase } from 'src/helpers'
import DocumentViwer from 'src/shadcn/components/custom/DocumentViwer'
import RenderData from '../RenderData'
import * as modules from './modules'

type Props = {
  projectDetails: any
}

const ViewProjectDetails = ({ projectDetails }: Props) => {

  const {  ProjectOverview, projectCategories } = modules

  return (
    <div className='flex flex-col space-y-2 text-xs'>
      {
        <div className='grid grid-cols-1 gap-4 p-2 bg-[#CF8C11]/10 my-2 '>
          <div className='col-span-2'>
            <div className='text-gray-600 col-span-2 border border-primary border-l-0 border-r-0 border-t-0 border-b-2 uppercase font-bold py-2'>"Project Overview</div>
            {
              Object.keys(ProjectOverview).filter((k: any) => !(["imagesAttachments", "headerImage", "videoAttachments"].includes(k))).map((k: any) => {
                var newVal = projectDetails && Object.keys(projectDetails).includes(k) ? projectDetails[k] : null
                return <div key={`project overview-${k}`} className='grid grid-cols-3 gap-2 my-2'>
                  <div className='text-gray-600 flex'> {convertToCamelCase(k) || "-"} </div>
                  <div className='flex space-x-2 col-span-2 ml-4 md:ml-0' >{
                    Object.keys(modules).includes(k) && projectDetails && projectDetails[k] ?
                      <DocumentViwer url={projectDetails[k]} /> :
                      <div className='flex items-center space-x-2'>
                        <RenderData value={projectDetails ? newVal : undefined} />
                      </div>}</div>
                </div>
              })
            }
          </div>
        </div>
      }
      {
        <div className='grid grid-cols-1 gap-4 p-2 bg-[#CF8C11]/10 my-2 '>
          <div className='col-span-2'>
            <div className='text-gray-600 col-span-2 border border-primary border-l-0 border-r-0 border-t-0 border-b-2 uppercase font-bold py-2'>{projectDetails?.projectCategoryType}</div>
            {
              projectCategories[projectDetails?.projectCategoryType as keyof object] ?
                Object.keys(projectCategories[projectDetails.projectCategoryType as keyof object]).filter((k: any) => !(["imagesAttachments", "headerImage", "videoAttachments"].includes(k))).map((k: any) => {
                  var newVal = projectDetails && Object.keys(projectDetails).includes(k) ? projectDetails[k] : null
                  return <div key={`project overview-${k}`} className='grid grid-cols-3 gap-2 my-2'>
                    <div className='text-gray-600 flex'> {convertToCamelCase(k) || "-"} </div>
                    <div className='flex space-x-2 col-span-2 ml-4 md:ml-0' >{
                      Object.keys(modules).includes(k) && projectDetails && projectDetails[k] ?
                        <DocumentViwer url={projectDetails[k]} /> :
                        <div className='flex items-center space-x-2'>
                          <RenderData value={projectDetails ? newVal : undefined} />
                        </div>}</div>
                  </div>
                })
                : Object.keys(modules.LeaseRoofArea).filter((k: any) => !(["imagesAttachments", "headerImage", "videoAttachments"].includes(k))).map((k: any) => {
                  var newVal = projectDetails && Object.keys(projectDetails).includes(k) ? projectDetails[k] : null
                  return <div key={`project overview-${k}`} className='grid grid-cols-3 gap-2 my-2'>
                    <div className='text-gray-600 flex'> {convertToCamelCase(k) || "-"} </div>
                    <div className='flex space-x-2 col-span-2 ml-4 md:ml-0' >{
                      Object.keys(modules).includes(k) && projectDetails && projectDetails[k] ?
                        <DocumentViwer url={projectDetails[k]} /> :
                        <div className='flex items-center space-x-2'>
                          <RenderData value={projectDetails ? newVal : undefined} />
                        </div>}</div>
                  </div>
                })
            }
          </div>
        </div>
      }

    </div>
  )
}

export default ViewProjectDetails