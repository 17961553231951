import React, { useEffect, useState, useRef } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import * as turf from "@turf/turf";
import exampleTile from "../../../assets/solar-panel-2.png";
// import "./styles.css";
import { mapStyle } from "./data";
import html2canvas from "html2canvas";
import { catchAsync, IS_SUCCESS_RESPONSE, SINGLE_PANEL_AREA_IN_SQ_M, stringifyObjectToRequestQuery, toAbsoluteUrl, TYPE_OF_SPACE_ENUM } from "src/helpers";
import { useDispatch } from "react-redux";
import { getTempDataRootState, setMapData, setQuotationdetails } from "src/redux/reducers/tempDataSlice";
import LoadingDialog from "src/shadcn/components/custom/LoadingDialog";
import { createQuotationProjectRequestApi, updateMapDetailsRequestApi, updateQuotationProjectRequestApi } from "src/services/requests/Projects";
import { Button } from "src/shadcn/components/ui";
import { MAIN_ROUTES_CONST } from "src/navigations/modules";
import { createQuotationRequestRequestApi } from "src/services/requests/QuotationRequests";
import { getAddressDetailsByLatLngRequestApi } from "src/services/requests/Common";
import { useSelector } from "react-redux";
import { useMainContext } from "../MainContext";
import { BASE_URL, GOOGLE_MAP_URL } from "src/services/consts/api_const";

const MapCapture = ({ }) => {
  // const location = useLocation();
  const { mapLocation: location, setMapLocation, totalSelectedArea, setTotalSelectedArea, setTotalMapObstacleArea, totalMapObstacleArea, totalMapPanelArea, setTotalMapPanelArea, setTotalMapPanelCounts, settotalObstaclesCount, setSubmitDetails,submitDetail, setQuotation, setProId } = useMainContext()
  const PEAK_POWER = 6
  const EFFICIENCY = 0.24
  const navigate = useNavigate();
  const { id } = useParams()
  const { bounds, zoom } = location || {};
  const [map, setMap] = useState(null);
  const [tiles, setTiles] = useState([]);
  const [obstacles, setObstacles] = useState([]); // Store obstacle shapes
  const [polygon, setPolygon] = useState(null);
  const [step, setStep] = useState(0);
  const [polygonArea, setPolygonArea] = useState(0);
  const [obstaclesArea, setObstaclesArea] = useState(0);
  const [panelsArea, setPanelsArea] = useState(0);
  //const [submitDetail, setSubmitDetail] = useState(false);
  const dispatch = useDispatch()
  const mapRef = useRef(null);
  const controlesArea = useRef(null);
  const [isLoading, setisLoading] = useState(false)
  const [selectedBounds, setselectedBounds] = useState(null)
  const { residentialUserDetails } = useSelector((state) => getTempDataRootState(state))
  const [selectedUserMapDetail, setselectedUserMapDetail] = useState(null)

  useEffect(() => {
    // Modify HTMLCanvasElement prototype's getContext method
    const originalGetContext = HTMLCanvasElement.prototype.getContext;
    HTMLCanvasElement.prototype.getContext = function (type, attribs) {
      attribs = attribs || {};
      attribs.preserveDrawingBuffer = true;
      return originalGetContext.call(this, type, attribs);
    };

    // Cleanup function to restore original getContext method
    return () => {
      HTMLCanvasElement.prototype.getContext = originalGetContext;
    };
  }, []);

  useEffect(() => {
    setQuotation(captureScreenshot);      
  }, [setQuotation]);

  useEffect(() => {
    if (!bounds) {
      navigate("/");
      return;
    }

    const loadGoogleMapsScript = () => {
      const script = document.createElement("script");
      script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyD-DdI-LfalDIB2xAhjIdGNBLwtldQAEeM&libraries=drawing,places,geometry`;
      script.async = true;
      script.onload = () => initializeMap(); // Initialize map once script is loaded
      document.head.appendChild(script);
    };




    const initializeMap = () => {
      // Initialize the Google Map
      const googleMap = new window.google.maps.Map(
        document.getElementById("map"),
        {
          center: {
            lat: (bounds.north + bounds.south) / 2,
            lng: (bounds.east + bounds.west) / 2,
          },
          zoom: zoom,
          zoomControl: false,
          fullscreenControl: false,
          // gestureHandling: "none",
          styles: mapStyle,
          disableDefaultUI: true, // Remove all default controls
          mapTypeControl: false,
          mapTypeId: window.google.maps.MapTypeId.SATELLITE, // Satellite view
          mapId: "9a7177352ef94f3d", // Replace with your Map Style ID (optional)
          gestureHandling: "none",
          tilt: 0, // Set tilt for 3D view
          heading: 0, // Initial heading for rotation
        }
      );

      // Set up the DrawingManager to draw polygons and obstacles
      const drawingManager = new window.google.maps.drawing.DrawingManager({
        drawingMode: window.google.maps.drawing.OverlayType.POLYGON,
        drawingControlOptions: {
          position: window.google.maps.ControlPosition.TOP_CENTER,
          drawingModes: ["polygon", "circle", "rectangle"],
        },
        polygonOptions: {
          editable: true,
          strokeColor: "#85A3FF", // Line color (red in this example)
          strokeOpacity: 0.8, // Line opacity
          strokeWeight: 3, // Line width (thickness)
          fillColor: "#FFF", // Fill color
          fillOpacity: 0.1, // Fill opacity
        },
        circleOptions: {
          strokeColor: "#85A3FF", // Line color (red in this example)
          strokeOpacity: 0.8, // Line opacity
          strokeWeight: 3, // Line width (thickness)
          fillColor: "#FFF", // Fill color
          fillOpacity: 0.1, // Circle stroke thickness
          // clickable: true, // Allow the circle to be clickable
          // editable: true, // Allow the circle to be edited
          // draggable: true, // Allow the circle to be draggable
        },
        rectangleOptions: {
          strokeColor: "#85A3FF", // Line color (red in this example)
          strokeOpacity: 0.8, // Line opacity
          strokeWeight: 3, // Line width (thickness)
          fillColor: "#FFF", // Fill color
          fillOpacity: 0.1, // Circle stroke thickness
          // clickable: true, // Allow the circle to be clickable
          // editable: true, // Allow the circle to be edited
          // draggable: true, // Allow the circle to be draggable
        },
        map: googleMap,
      });




      drawingManager.setMap(googleMap);

      // const savedData = JSON.parse(localStorage.getItem("mapDrawingData"));
      // if(savedData){
      //   if(savedData?.polygons){
      //     let data = savedData?.polygons?.getArray()
      //     const defaultPolygon = new window.google.maps.Polygon({
      //       paths: data,
      //       strokeColor: "#85A3FF",
      //       strokeOpacity: 0.8,
      //       strokeWeight: 3,
      //       fillColor: "#FFF",
      //       fillOpacity: 0.1,
      //       editable: true,
      //     });
      //     defaultPolygon.setMap(googleMap);
      //   }
      // }



      setMap(googleMap); // Store map instance in state

      window.google.maps.event.addListener(
        drawingManager,
        "polygoncomplete",
        (polygon) => {
          // Generate tiles for the drawn polygon
          setPolygon(polygon);

          drawingManager.setDrawingMode(null);
        }
      );

      window.google.maps.event.addListener(
        drawingManager,
        "circlecomplete",
        (circle) => {
          // Add circle as an obstacle
          addObstacle(circle);
        }
      );

      window.google.maps.event.addListener(
        drawingManager,
        "rectanglecomplete",
        (rectangle) => {
          // Add rectangle as an obstacle
          addObstacle(rectangle);
        }
      );


      // let polygondata = new window.google.maps.Polygon({
      //   paths: polygonPath,
      //   strokeColor: "#85A3FF",
      //   strokeOpacity: 0.8,
      //   strokeWeight: 3,
      //   fillColor: "#FFF",
      //   fillOpacity: 0.1,
      //   editable: true,
      //   map: map, // Ensure map instance is already initialized
      // });
      // setPolygon(polygondata)

    };

    loadGoogleMapsScript(); // Load Google Maps with the drawing library
  }, [bounds, zoom, navigate]);

  const loadDrawingData = () => {
    const savedData = JSON.parse(localStorage.getItem("mapDrawingData"));
    if (!savedData) return;
  };

  // Function to calculate the area of a polygon
  const calculatePolygonArea = () => {
    // Convert the polygon's LatLng path to GeoJSON coordinates
    const paths = polygon
      .getPath()
      .getArray()
      .map((coord) => [coord.lng(), coord.lat()]);

    paths.push(paths[0]); // Close the polygon by repeating the first point


    // Create a GeoJSON polygon
    const polygonGeoJSON = turf.polygon([paths]);
    // Calculate the area (in square meters)
    const area = turf.area(polygonGeoJSON);
    setPolygonArea(area);
    setTotalSelectedArea(area)
    document.getElementById("shape-info").innerHTML =
      "Rooftop Area: " + parseFloat(area).toFixed(4) + " square meters <br/>";

    // setTotalMapPanelArea(area)
    // setTotalMapPanelArea(area)
    setTotalSelectedArea(area)


    // const savedData = JSON.parse(localStorage.getItem("mapDrawingData"));
    // let data = {
    //   ...savedData,
    //   polygons: polygon
    // }
    // localStorage.setItem("mapDrawingData", JSON.stringify(data));
    return area;
  };

  const polygonWithObstacles = () => {
    const obstacleArea = calculateObstacleArea();
    const overAllArea = polygonArea - obstacleArea;
    setObstaclesArea(obstacleArea);
    setTotalMapObstacleArea(obstacleArea)
    let text =
      "Rooftop Area without obstacles: " +
      parseFloat(overAllArea).toFixed(4) +
      " sq. mt.";
    document.getElementById("shape-info").innerHTML = text;
    document.getElementById("obstacles-info").innerHTML =
      "Obstacles Area: " + parseFloat(obstacleArea).toFixed(4) + " sq. mt.";
  };

  // Function to get the bounds of the polygon
  const getPolygonBounds = (polygon) => {
    const bounds = new window.google.maps.LatLngBounds();
    polygon.getPath().forEach((coord) => bounds.extend(coord)); // Extend bounds for each point in the polygon
    return bounds;
  };

  const calculateObstacleArea = () => {
    if (!obstacles || !Array.isArray(obstacles)) {
      console.error("Invalid obstacles array", obstacles);
      return 0;
    }

    let totalArea = 0;

    obstacles.forEach((obstacle) => {
      if (obstacle instanceof window.google.maps.Rectangle) {
        const bounds = obstacle.getBounds();
        if (bounds instanceof window.google.maps.LatLngBounds) {
          const ne = bounds.getNorthEast();
          const sw = bounds.getSouthWest();

          // Calculate area (approximation)
          const area = window.google.maps.geometry.spherical.computeArea([
            sw,
            new window.google.maps.LatLng(sw.lat(), ne.lng()),
            ne,
            new window.google.maps.LatLng(ne.lat(), sw.lng()),
          ]);

          totalArea += area;
        } else {
          console.error("Invalid rectangle bounds", bounds);
        }
      } else if (obstacle instanceof window.google.maps.Circle) {
        const radius = obstacle.getRadius();

        // Calculate area of the circle
        const area = Math.PI * Math.pow(radius, 2); // Area in square meters
        totalArea += area;
      } else if (obstacle instanceof window.google.maps.Polygon) {
        // Calculate area for polygon
        const paths = obstacle.getPath();
        const area = window.google.maps.geometry.spherical.computeArea(paths);
        totalArea += area;
      } else {
        console.error("Unknown obstacle type", obstacle);
      }
    });

    let totalOnstacles = obstacles.map((obstacle) => {
      const type =
        obstacle instanceof window.google.maps.Rectangle
          ? "rectangle"
          : "spherical";

      const overlayBounds = obstacle.getBounds();
      const north = overlayBounds.getNorthEast().lat();
      const south = overlayBounds.getSouthWest().lat();
      const east = overlayBounds.getNorthEast().lng();
      const west = overlayBounds.getSouthWest().lng();
      return { bounds: { north, south, east, west }, type };
    });

    settotalObstaclesCount(totalOnstacles.length)


    console.log("Total Obstacle Area (sq meters):", totalArea);
    return totalArea;
  };

  const isTileOverlappingObstacle = (tileBounds) => {
    if (!(tileBounds instanceof window.google.maps.LatLngBounds)) {
      console.error("Invalid tileBounds provided", tileBounds);
      return false;
    }

    return obstacles.some((obstacle) => {
      if (obstacle instanceof window.google.maps.Rectangle) {
        const obstacleBounds = obstacle.getBounds();
        if (obstacleBounds instanceof window.google.maps.LatLngBounds) {
          return tileBounds.intersects(obstacleBounds);
        } else {
          console.error("Invalid rectangle bounds", obstacleBounds);
          return false;
        }
      } else if (obstacle instanceof window.google.maps.Circle) {
        const circleCenter = obstacle.getCenter();
        const circleRadius = obstacle.getRadius();

        // Generate bounds for the circle
        const circleBounds = new window.google.maps.LatLngBounds(
          window.google.maps.geometry.spherical.computeOffset(
            circleCenter,
            circleRadius,
            225
          ),
          window.google.maps.geometry.spherical.computeOffset(
            circleCenter,
            circleRadius,
            45
          )
        );

        if (circleBounds instanceof window.google.maps.LatLngBounds) {
          return tileBounds.intersects(circleBounds);
        } else {
          console.error("Invalid circle bounds", circleBounds);
          return false;
        }
      } else {
        console.error("Unknown obstacle type", obstacle);
        return false;
      }
    });
  };

  // Function to add obstacles (circle or rectangle)
  const addObstacle = (obstacle) => {
    setObstacles((prevObstacles) => {
      const newObstacles = [...prevObstacles, obstacle]; // Add new obstacle to the previous state
      console.log(newObstacles, "Added");
      return newObstacles;
    });
  };

  // Function to generate tiles inside a polygon
  const addTilesToPolygon = (polygon, map) => {
    setisLoading(true)
    const bounds = getPolygonBounds(polygon);
    const tileSize = 0.00002; // Adjust tile size as needed

    const paths = polygon.getPath().getArray().map(coord => [coord.lng(), coord.lat()]);
    paths.push(paths[0]); // Close the polygon
    const polygonGeoJSON = turf.polygon([paths]);

    const newTiles = []; // Array to store tiles
    const tileLat = 2.3 / 364000
    const tileLong = 1.5 / 288200
    const startLat = bounds.getSouthWest().lat() - tileLat;
    const startLng = bounds.getSouthWest().lng() - tileLong;
    const endLat = bounds.getNorthEast().lat();
    const endLng = bounds.getNorthEast().lng();

    // Pre-calculate bounds for obstacles if possibl

    // Adjusted loop to start from exact South-West corner
    for (let lat = startLat; lat < endLat; lat += tileLat) {
      for (let lng = startLng; lng < endLng; lng += tileLong) {
        const tile = [
          [lng, lat],
          [lng, lat + tileSize],
          [lng + tileSize, lat + tileSize],
          [lng + tileSize, lat],
          [lng, lat],
        ];
        const tileGeoJSON = turf.polygon([tile]);

        if (turf.booleanContains(polygonGeoJSON, tileGeoJSON)) {
          const tileBounds = new window.google.maps.LatLngBounds(
            new window.google.maps.LatLng(lat, lng),
            new window.google.maps.LatLng(lat + tileSize, lng + tileSize)
          );

          // Check for overlap with obstacles
          if (isTileOverlappingObstacle(tileBounds, obstacles)) {
            console.log("Tile overlaps with obstacle, skipping");
            continue; // Skip adding the tile if it overlaps with an obstacle
          }

          const overlayBounds = {
            north: tileBounds.getNorthEast().lat(),
            south: tileBounds.getSouthWest().lat(),
            east: tileBounds.getNorthEast().lng(),
            west: tileBounds.getSouthWest().lng(),
          };

          console.log({ overlayBounds });

          // Add PNG as GroundOverlay
          const groundOverlay = new window.google.maps.GroundOverlay(
            exampleTile,
            overlayBounds,
            {
              opacity: 0.7, // Optional: Set image transparency
            }
          );
          newTiles.push(groundOverlay);
          groundOverlay.setMap(map);
        }
      }
    }

    console.log(newTiles, "Tiles Detail");
    calculateGroundOverlayAreas(newTiles);
    setTiles(prevTiles => [...prevTiles, ...newTiles]);
    let TOTAL_PANEL_COUNTS = Math.floor(totalSelectedArea / SINGLE_PANEL_AREA_IN_SQ_M)
    setTotalMapPanelCounts(TOTAL_PANEL_COUNTS)
    setTotalMapPanelArea(TOTAL_PANEL_COUNTS * SINGLE_PANEL_AREA_IN_SQ_M)


    const tilesBounds = newTiles.map(tile => {
      const overlayBounds = tile.getBounds();
      return {
        north: overlayBounds.getNorthEast().lat(),
        south: overlayBounds.getSouthWest().lat(),
        east: overlayBounds.getNorthEast().lng(),
        west: overlayBounds.getSouthWest().lng(),
      };
    });

    // setTotalMapPanelCounts(tilesBounds.length);
    setStep(3);
    setisLoading(false)
    setSubmitDetails(true)
    // Add new tiles to the state
  };

  const calculateGroundOverlayAreas = (groundOverlays) => {
    if (!window.google || !window.google.maps || !window.google.maps.geometry) {
      console.error("Google Maps geometry library is not loaded.");
      return 0;
    }

    let totalArea = 0;

    groundOverlays.forEach((overlay) => {
      const bounds = overlay.getBounds(); // Get the bounds of the GroundOverlay
      if (!bounds) {
        console.error("Invalid GroundOverlay bounds:", overlay);
        return;
      }

      // Get corners of the bounds
      const sw = bounds.getSouthWest();
      const ne = bounds.getNorthEast();
      const nw = new window.google.maps.LatLng(ne.lat(), sw.lng());
      const se = new window.google.maps.LatLng(sw.lat(), ne.lng());

      // Create an array of LatLng points representing the rectangle
      const rectangleVertices = [sw, se, ne, nw];

      // Calculate the area of the rectangle
      const area =
        window.google.maps.geometry.spherical.computeArea(rectangleVertices);
      totalArea += area;
    });
    document.getElementById(
      "panel-info"
    ).innerHTML = `Panels Area: ${totalArea.toFixed(4)} sq. mt.`;
    // setPanelsArea(totalArea.toFixed(2));
    // setTotalMapPanelArea(totalArea.toFixed(2))

    return totalArea;
  };

  // Function to remove all obstacles
  const removeObstacles = () => {
    obstacles.forEach((obstacle) => obstacle.setMap(null));
    setObstacles([]); // Clear the obstacles state
  };

  const confirmArea = () => {
    setStep(1);
    calculatePolygonArea();
  };

  const confirmObstacles = () => {
    setStep(2);
    polygonWithObstacles();
  };

  const addTiles = () => {
    addTilesToPolygon(polygon, map);
  };

  if (!bounds) {
    return null;
  }

  const captureScreenshot = async () => {

    if (mapRef.current) {
      if (controlesArea.current) {
        controlesArea.current.style.display = "none";
      }
      setisLoading(true)
      const canvas = await html2canvas(mapRef.current, {
        useCORS: true, // Enables cross-origin images
        logging: true,
      });
      const image = canvas.toDataURL("image/png");
      uploadImage(image);
    }
  };

  const uploadImage = async (base64String) => {
    // Example Base64 string (truncated for readability)

    // Function to convert Base64 to Blob
    function base64ToBlob(base64, mimeType = "image/png") {
      const byteCharacters = atob(base64.split(",")[1]); // Decode Base64
      const byteNumbers = new Array(byteCharacters.length)
        .fill()
        .map((_, i) => byteCharacters.charCodeAt(i));
      const byteArray = new Uint8Array(byteNumbers);
      return new Blob([byteArray], { type: mimeType });
    }

    // Convert the Base64 string to a Blob
    const blob = base64ToBlob(base64String, "image/png");

    // Optional: Convert Blob to File (useful for uploading with a filename)
    const file = new File([blob], "uploaded_image.png", { type: "image/png" });

    // Upload the file using FormData
    const formData = new FormData();
    formData.append("file", file);

    // Send the file via fetch (example for a POST request)
    await fetch(`${BASE_URL}/upload_image`, {
      method: "POST",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("File uploaded successfully!", data);
        uploadMapData(data);
      })
      .catch((error) => {
        console.error("Error uploading file:", error);
      });
  };

  const uploadMapData = async (data) => {
    const fileUrl = data.fileUrl;
    const polygonDetail = polygon
      .getPath()
      .getArray()
      .map((latLng) => ({
        lat: latLng.lat(),
        lng: latLng.lng(),
      }));

    const allObstacles = obstacles.map((obstacle) => {
      const type =
        obstacle instanceof window.google.maps.Rectangle
          ? "rectangle"
          : "spherical";

      const overlayBounds = obstacle.getBounds();
      const north = overlayBounds.getNorthEast().lat();
      const south = overlayBounds.getSouthWest().lat();
      const east = overlayBounds.getNorthEast().lng();
      const west = overlayBounds.getSouthWest().lng();
      return { bounds: { north, south, east, west }, type };
    });

    const tilesBounds = tiles.map((tile) => {
      const overlayBounds = tile.getBounds();
      const north = overlayBounds.getNorthEast().lat();
      const south = overlayBounds.getSouthWest().lat();
      const east = overlayBounds.getNorthEast().lng();
      const west = overlayBounds.getSouthWest().lng();
      return { north, south, east, west };
    });

    setselectedBounds(polygonDetail[0])


    const map = {
      bounds,
      zoom,
    };
    const area = {
      polygon: polygonArea,
      panels: panelsArea,
      obstacles: obstaclesArea,
    };

    let userMapDetail = {
      enquiryId: id,
      map,
      area,
      polygon: polygonDetail,
      panels: tilesBounds,
      obstacles: allObstacles,
      imageUrl: fileUrl,
    };

    setselectedUserMapDetail(userMapDetail)
    dispatch(setMapData(userMapDetail))
    console.log(JSON.stringify(userMapDetail), "REQUEST", polygonDetail[0]);


    let addreessFromLatlOng = await getAddressDetailsByLatLngRequestApi(stringifyObjectToRequestQuery({
      lat: polygonDetail[0].lat,
      lang: polygonDetail[0].lng
    }))

    let totalGeneration = ((parseInt(tilesBounds.length) * 600) / 1000)
    const ENERGY_GENERATION_FROM_SINGLE_PANNEL = SINGLE_PANEL_AREA_IN_SQ_M * 5 * EFFICIENCY
    const ENERGY_GENERATION_FROM_PANELS = totalMapPanelArea * ENERGY_GENERATION_FROM_SINGLE_PANNEL
    const CONVERT_ENERGY_TO_POWER = ENERGY_GENERATION_FROM_PANELS / PEAK_POWER
    console.log({ addreessFromLatlOng });
    if (IS_SUCCESS_RESPONSE(addreessFromLatlOng)) {
      if (addreessFromLatlOng?.data?.pincode) {
        let postData = {
          "freeAreaAvailableOnRoofTop": parseFloat(polygonArea).toFixed(4) + " SqFt",
          "freeAreaAvailableOnGround": "0",
          "typeOfSpace": TYPE_OF_SPACE_ENUM.Rooftop,
          "monthlyBillAmount": 0,
          "unitRequiredPerMonth": 0,
          "contactPersonNumber": residentialUserDetails?.phoneNumber,
          "pinCode": addreessFromLatlOng?.data?.pincode,
          "customerType": "Residential",
          "dailyGeneration": (totalGeneration),
          "totalArea": totalSelectedArea, 
          "totalPanels": totalMapPanelArea,
          "totalEnergyGenerationFromPanels": ENERGY_GENERATION_FROM_PANELS,
          "totalEnergy": CONVERT_ENERGY_TO_POWER
        }
        let createProjectApiRespo = await createQuotationProjectRequestApi(postData)
        if (IS_SUCCESS_RESPONSE(createProjectApiRespo)) {
          // handletouploadmapdata(result._id)

          let response = await fetch("https://ba.energymarketplace.in/api/v1/solar-map", {
            method: "POST",
            headers: {
              "Content-Type": "application/json", // Specify the content type
            },
            body: JSON.stringify({
              ...userMapDetail,
              enquiryId: createProjectApiRespo?._id
            }),
          });

          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }

          let mapApiRep = await response.json();
          console.log("Data uploaded successfully!", mapApiRep);
          let mapDataId = mapApiRep?.data?._id;

          if (mapApiRep) {
            let apiResponseForUpdateProject = await updateQuotationProjectRequestApi(createProjectApiRespo?._id, {
              mapDataId
            })

            if (IS_SUCCESS_RESPONSE(apiResponseForUpdateProject)) {
              setProId(createProjectApiRespo?._id)
              //navigate("/" + MAIN_ROUTES_CONST.customerJourney + "/" + createProjectApiRespo?._id)
            }
          }

        }
      }
    }
    setisLoading(false)

    // if (mapDataId) {
    //   updateProjectMapDataID(mapDataId)
    // }

  };


  const resetPolygonDrawing = () => {
    if (polygon) {
      polygon.setMap(null);
      setPolygon(null);
      setTotalSelectedArea(undefined)
    }
    setTotalSelectedArea(undefined)
    setPolygonArea(0);
    setStep(0);
  };

  const resetObstacleDrawings = () => {
    obstacles.forEach(obstacle => obstacle.setMap(null));
    setObstacles([]);
    setObstaclesArea(0);
    setTotalMapObstacleArea(undefined)
    setStep(1);
    tiles.forEach(tile => tile.setMap(null));
    setTiles([]);
    setPanelsArea(0);
    setTotalMapPanelCounts(undefined)
    setTotalMapPanelArea(undefined)
  };


  const resetPanelsDrawing = () => {
    tiles.forEach(tile => tile.setMap(null));
    setTiles([]);
    setPanelsArea(0);
    setTotalMapPanelCounts(undefined)
    setTotalMapPanelArea(undefined)
    setStep(2);
  };

  console.log({ selectedBounds, selectedUserMapDetail, polygon, obstacles });


  return (
    <>
      {/* Map container */}
      <div
        ref={mapRef}
        style={{ width: "100%", height: "100%", position: "relative" }
        }
      >
        <div id="map" className="map" />
        <div id="controls-area" ref={controlesArea} className='absolute left-0 right-0 top-10 bg-slate-900 w-full p-4 flex justify-between items-center space-x-2'>
          <div className="grid grid-cols-3 gap-4">
            <Button onClick={() => {
              setMapLocation(undefined)
              setTotalSelectedArea(undefined)
              setTotalMapObstacleArea(undefined)
              setTotalMapPanelArea(undefined)
              settotalObstaclesCount(undefined)
              setTotalMapPanelCounts(undefined)

            }}>Back</Button>
          </div>
          <div className="flex space-x-2 justify-end">
            <div>
              {<div className=" flex space-x-2">
                {step > 0 && <Button variant={'outlinePrimary'} onClick={resetPolygonDrawing}>Reset Area</Button>}
                {step > 1 && <Button variant={'outlinePrimary'} onClick={resetObstacleDrawings}>Reset Obstacles</Button>}
                {step > 2 && <Button variant={'outlinePrimary'} onClick={resetPanelsDrawing}>Reset Panels</Button>}
              </div>}
            </div>
            <div>
              {
                !submitDetail && (
                  <button onClick={removeObstacles} className="button remove-obstacles">
                    <i className="fas fa-sync-alt"></i>
                  </button>
                )
              }

              {/* Information Panel */}
              {
                !submitDetail && (
                  <div className="info-panel">
                    <div id="shape-info"></div>
                    <div id="obstacles-info" className="info obstacles-info"></div>
                    <div id="panel-info" className="info panel-info"></div>
                  </div>
                )
              }

              {/* Conditional Buttons for Different Steps */}
              {
                polygon && step === 0 && (
                  <Button className="w-full" onClick={confirmArea} >
                    Confirm Rooftop
                  </Button>
                )
              }

              {
                step === 1 && (
                  <Button className="w-full" onClick={confirmObstacles} >
                    Confirm Obstacles
                  </Button>
                )
              }

              {
                step === 2 && (
                  <Button className="w-full" onClick={addTiles} >
                    Place Panels
                  </Button>
                )
              }
              
            </div>
          </div>
        </div>

      </div >

      <LoadingDialog isOpen={isLoading} message="calculating Area and submitting request" />
    </>
  );
};

export default MapCapture;
