import { checkDecimal, FormField } from "src/helpers";
import * as Yup from 'yup';

export interface ISolarPlanArr {
  name: string;
  projectPrice: number;
  overAllPrice: {
    totalPrice: number;
    discountPrice: number;
  };
  emiPrice: {
    totalPrice: number;
    discountPrice: number;
  };
  benefits: string[];
  unlockPrice: number;

}

export const SolarPlanArr: any[] = [
  {
    id: "6745523b7dd8e8ba22819aa8",
    name: "Economy",
    projectPrice: 228000,
    overAllPrice: {
      totalPrice: 236448,
      discountPrice: 228000,
    },
    emiPrice: {
      totalPrice: 14954,
      discountPrice: 14250,
    },
    "features": {
      "age": "Less than 5 Years",
      "efficiency": "Below 18%",

      "subsidyEligible": true
    },
    "typicalRate": 57000,
    "interestSavings": 8448,

    benefits: [
      "Less than <b>5 Years</b>",
      "Efficiency <b>Below 18 %</b>",
      // "<b>Indian</b> Brands",
      "<b>Eligible</b> for subsidy",
      `Typical Rate: <b>${process.env.REACT_APP_KW_PRICE_EC ? checkDecimal((process.env.REACT_APP_KW_PRICE_EC)?.toString(), true) : 0}/kW</b>`,
    ],
    unlockPrice: 8448,
  },
  {
    id: "67454c830a6d4798c2afb373",
    name: "STANDARD",
    projectPrice: 250000,
    overAllPrice: {
      totalPrice: 259264,
      discountPrice: 250000,
    },
    emiPrice: {
      totalPrice: 16397,
      discountPrice: 15625,
    },
    "features": {
      "age": "5-10 Years",
      "efficiency": "18%-21%",

      "subsidyEligible": true
    },
    "typicalRate": 60000,
    "interestSavings": 9264,
    benefits: [
      "<b>5 -10 Years</b>",
      "Efficiency <b>18% - 21 %</b>",
      // "<b>Indian</b> Brands",
      "<b>Eligible</b> for subsidy",
      `Typical Rate: <b>${process.env.REACT_APP_KW_PRICE_SD ? checkDecimal((process.env.REACT_APP_KW_PRICE_SD)?.toString(), true) : 0}/kW</b>`,
    ],
    unlockPrice: 9264,
  },
  {
    id: "674471997bd061ea020da34b",
    name: "premium",
    projectPrice: 250000,
    overAllPrice: {
      totalPrice: 259264,
      discountPrice: 250000,
    },
    emiPrice: {
      totalPrice: 16397,
      discountPrice: 15625,
    },
    "features": {
      "age": "Above 10 Years",
      "efficiency": "Above 21%",

      "subsidyEligible": true
    },
    "typicalRate": "60,000/kW",
    "interestSavings": 9264,
    benefits: [
      "Above <b>10 Years</b>",
      "Efficiency Above <b> 21%</b>",
      // "<b>Indian</b> Brands",
      "<b>Eligible</b> for subsidy",
      `Typical Rate: <b>${process.env.REACT_APP_KW_PRICE_PR ? checkDecimal((process.env.REACT_APP_KW_PRICE_PR)?.toString(), true) : 0}/kW</b>`,
    ],
    unlockPrice: 9264,
  },
];

export const PAYMENT_FORM_FIELDS: FormField[] = [
  {
    label: 'Card Name',
    dataKey: 'cardName',
    componentType: 'input',
    componentProps: {
      type: "text",
      placeholder: 'Name on Card',
      maxLength: 50,
      required: true,
    },
  },
  {
    label: 'Card Number',
    dataKey: 'cardNumber',
    componentType: 'input',
    componentProps: {
      type: "text",
      placeholder: 'Card Number',
      maxLength: 16, // Assuming 16 digits for standard cards
      regex: /^[0-9]{16}$/, // Ensure it's exactly 16 digits
      required: true,
    },
  },
  {
    label: 'Expiry',
    dataKey: 'expiry',
    componentType: 'input',
    componentProps: {
      type: "text",
      placeholder: 'MM/YY',
      regex: /^(0[1-9]|1[0-2])\/\d{2}$/, // Format: MM/YY
      required: true,
    },
  },
  {
    label: 'CVV',
    dataKey: 'cvv',
    componentType: 'input',
    componentProps: {
      type: "password", // To mask CVV input for security
      placeholder: 'CVV',
      maxLength: 3, // Standard 3-digit CVV
      regex: /^[0-9]{3}$/, // Ensure it's exactly 3 digits
      required: true,
    },
  },
  {
    label: 'Coupon Code',
    dataKey: 'couponCode',
    componentType: 'input',
    componentProps: {
      type: "text",
      placeholder: 'Coupon Code (Optional)',
      maxLength: 20,
      required: false,
    },
  },
];

export interface IPaymentForm {
  cardName: string;
  cardNumber: string;
  expiry: string;
  cvv: string;
  couponCode?: string; // Optional field
}

export const initialPaymentForm: IPaymentForm = {
  cardName: "",
  cardNumber: "",
  expiry: "",
  cvv: "",
  couponCode: "",
};


export const paymentFormSchema = Yup.object().shape({
  cardName: Yup.string()
    .trim()
    .required('Card Name is required')
    .max(50, 'Card Name cannot exceed 50 characters'),

  cardNumber: Yup.string()
    .matches(/^[0-9]{16}$/, 'Card Number must be exactly 16 digits')
    .required('Card Number is required'),

  expiry: Yup.string()
    .matches(
      /^(0[1-9]|1[0-2])\/\d{2}$/,
      'Expiry must be in MM/YY format (e.g., 12/25)'
    )
    .required('Expiry is required'),

  cvv: Yup.string()
    .matches(/^[0-9]{3}$/, 'CVV must be exactly 3 digits')
    .required('CVV is required'),

  couponCode: Yup.string()
    .trim()
    .max(20, 'Coupon Code cannot exceed 20 characters'),
});

