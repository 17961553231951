import React from 'react'
import { FEATURES_BG } from 'src/helpers'

type Props = {}

const FeaturesView = (props: Props) => {
    return (
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6  md:px-16 mt-8 md:mt-12">
            {/* Features Section */}
            <div
                className="h-[400px] rounded-xl bg-cover bg-center bg-no-repeat flex items-center"
                style={{ backgroundImage: `url(${FEATURES_BG})` }}
            >
                <ul className="p-4 sm:p-6 md:p-8 space-y-4  rounded-xl text-white">
                    <li className="text-2xl sm:text-3xl font-bold">Features</li>
                    <li className="text-sm sm:text-lg">- High efficiency Maximum Power Point Tracking (MPPT) based inverter for maximum output</li>
                    <li className="text-sm sm:text-lg">- Remote monitoring communication interface available (optional)</li>
                    <li className="text-sm sm:text-lg">- Digital display for easy readability</li>
                    <li className="text-sm sm:text-lg">- Active fault monitoring unit for safe operations</li>
                </ul>
            </div>

            {/* Benefits Section */}
            <div
                className="h-[400px] rounded-xl bg-cover bg-center bg-no-repeat flex items-center"
                style={{ backgroundImage: `url(${FEATURES_BG})` }}
            >
                <ul className="p-4 sm:p-6 md:p-8 space-y-4  rounded-xl text-white">
                    <li className="text-2xl sm:text-3xl font-bold">Benefits</li>
                    <li className="text-sm sm:text-lg">- Cut down on your electricity bills</li>
                    <li className="text-sm sm:text-lg">- Utilize idle rooftop space</li>
                    <li className="text-sm sm:text-lg">- Sell excess power back to grid</li>
                    <li className="text-sm sm:text-lg">- Hedge against ever increasing electricity tariffs</li>
                    <li className="text-sm sm:text-lg">- Trusted, dependable and long lasting system</li>
                    <li className="text-sm sm:text-lg">- Green energy, clean energy</li>
                </ul>
            </div>
        </div>
    )
}

export default FeaturesView
