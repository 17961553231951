import { useFormik } from 'formik'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { catchAsync, checkDecimal, FormField, IS_SUCCESS_RESPONSE } from 'src/helpers'
import { USER_DETAILS_FIELDS, USER_DETAILS_INIT, USERDetailsFormSchema } from 'src/modules/ReStructure/CustomerJorney/modules'
import { MAIN_ROUTES_CONST } from 'src/navigations/modules'
import { getTempDataRootState, resetMapData } from 'src/redux/reducers/tempDataSlice'
import { RootState } from 'src/redux/store'
import { createQuotationProjectRequestApi, fetchProjectCalculationsRequestApi } from 'src/services/requests/Projects'
import CustomeDialog from 'src/shadcn/components/custom/CustomeDialog'
import LoadingDialog from 'src/shadcn/components/custom/LoadingDialog'
import RenderFormComponents from 'src/shadcn/components/custom/RenderFormComponents'
import { Button } from 'src/shadcn/components/ui'
import { MainContextProvider, useMainContext } from './MainContext'
import MapCapture from './MapCapture/MapCapture'
import MapFrame from './MapFrame/MapFrame'

type Props = {}

const Main = (props: Props) => {
    const { mapLocation, totalSelectedArea, totalMapObstacleArea, totalMapPanelArea, totalMapPanelCounts, totalObstaclesCount, callQuotation, submitDetail, projectId } = useMainContext()
    const { mapData, residentialUserDetails } = useSelector((state: RootState) => getTempDataRootState(state))
    const [isLoading, setisLoading] = useState<boolean>(false)
    const [showConfirmBox, setshowConfirmBox] = useState<boolean>(false)
    const navigate = useNavigate()
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(resetMapData())
        return () => { }
    }, [])

    useEffect(() => {
        if (projectId) {
            formik.handleSubmit()
        }
    }, [projectId])

    const formik = useFormik({
        initialValues: USER_DETAILS_INIT,
        validationSchema: USERDetailsFormSchema,
        onSubmit(values, formikHelpers) {
            if (projectId) {
                handleUpdateMonthlyBillAmount(values.billAmount)
            } else {
                callAPI()
            }
        },
    })

    const handleUpdateMonthlyBillAmount = catchAsync(
        async (monthlyUnits: string) => {
            return await fetchProjectCalculationsRequestApi({
                _id: projectId,
                monthlyBillAmount: monthlyUnits,
            })
        },
        (result: any) => {
            if (IS_SUCCESS_RESPONSE(result)) {
                navigate("/" + MAIN_ROUTES_CONST.customerJourney + "/" + projectId)
            }
        },
    )

    const callAPI = () => {
        callQuotation()
    }


    const handleCReateProject = catchAsync(async () => {
        setisLoading(true)
        let postData = {
            "freeAreaAvailableOnRoofTop": 0,
            "freeAreaAvailableOnGround": "0",
            "typeOfSpace": null,
            "monthlyBillAmount": formik.values.billAmount,
            "unitRequiredPerMonth": 0,
            "contactPersonNumber": residentialUserDetails?.phoneNumber,
            "pinCode": null,
            "customerType": "Residential",
            "dailyGeneration": null,
            "totalArea": null,
            "totalPanels": null,
            "totalEnergyGenerationFromPanels": null,
            "totalEnergy": null
        }
        return await createQuotationProjectRequestApi(postData)
    }, (result: any) => {
        if (IS_SUCCESS_RESPONSE(result)) {
            navigate("/" + MAIN_ROUTES_CONST.customerJourney + "/" + result?._id)
            setisLoading(false)
        }
    }, () => { setisLoading(false) })

    return (
        <div className="w-full max-w-[1200px] mx-auto p-4 sm:p-6 space-y-6">
            {/* Header */}
            <div className="font-bold text-primary text-xl uppercase py-4 text-center sm:text-left">
                Area Calculator
            </div>

            {/* Main Content */}
            <div className="flex flex-col lg:flex-row gap-6">
                {/* Map Section */}
                <div className="lg:w-3/4 w-full">
                    <div className="w-full h-[500px] sm:h-[400px] lg:h-[500px] relative">
                        {mapLocation ? <MapCapture /> : <MapFrame />}
                    </div>
                </div>

                {/* Report Section */}
                <div className="lg:w-1/4 w-full p-4 bg-gray-100 rounded-md">
                    <div className="flex flex-col space-y-4">
                        <div className="font-bold text-gray-500">Panel/Area Report</div>
                        <div className="flex flex-col space-y-4 text-base sm:text-lg lg:text-xl">
                            <div>
                                <div className="font-semibold">Area (Sq.M.)</div>
                                <div>{totalSelectedArea ? checkDecimal(totalSelectedArea, true) : 0}</div>
                            </div>
                            <div>
                                <div className="font-semibold">Obstacles Area (Sq.M.)</div>
                                <div>{totalMapObstacleArea ? checkDecimal(totalMapObstacleArea, true) : 0}</div>
                            </div>
                            <div>
                                <div className="font-semibold">Panels Area (Sq.M.)</div>
                                <div>{totalMapPanelArea ? checkDecimal(totalMapPanelArea, true) : 0}</div>
                            </div>
                            <div>
                                <div className="font-semibold">Panels Count (#)</div>
                                <div>{totalMapPanelCounts ? checkDecimal(totalMapPanelCounts, true) : 0}</div>
                            </div>
                            <div>
                                <div className="font-semibold">Total kW</div>
                                <div>{totalMapPanelArea ? checkDecimal(((parseInt(totalMapPanelCounts) * 600) / 1000).toString(), true) : 0}</div>
                            </div>

                            <div className="text-lg font-semibold">Enter Average Monthly Bill</div>
                            <div className="mb-4">
                                {USER_DETAILS_FIELDS.map((field: FormField) => (
                                    <div key={`new-enquiry-form-${field.dataKey}`}>
                                        <RenderFormComponents
                                            formik={formik}
                                            value={formik.values[field.dataKey as keyof object]}
                                            {...field}
                                            onChange={(e: any) => formik.setFieldValue(field.dataKey, e)}
                                        />
                                    </div>
                                ))}
                            </div>
                            {submitDetail && <Button
                                className="w-full"
                                onClick={() => formik.handleSubmit()}>
                                Get Personalized Quotation
                            </Button>}
                        </div>
                    </div>
                </div>
            </div>

            {/* Buttons */}
            <div className="flex justify-center gap-4">
                {mapData && (
                    <Button
                        className="w-[120px] sm:w-[150px]"
                        onClick={() => {
                            if (!mapData) {
                                setshowConfirmBox(true)
                            } else navigate("/" + MAIN_ROUTES_CONST.customerJourney + "/" + projectId)
                        }} >
                        Continue
                    </Button>
                )}
                <Button
                    onClick={() => {
                        setshowConfirmBox(true)
                        // navigate("/" + MAIN_ROUTES_CONST.customerJourney + "/" + projectId)
                    }}
                    className="w-[120px] sm:w-[150px] mt-[50px]"
                    variant={'outlinePrimary'}
                >
                    Skip
                </Button>
            </div>

            {/* Confirm Dialog */}
            <CustomeDialog
                className='md:max-w-md'
                isOpen={showConfirmBox}
                headerTitle={"Confirm"}
                onCancle={() => {
                    setshowConfirmBox(false)
                }}
                onSubmit={() => {
                    if (formik.values.billAmount) {
                        // navigate("/" + MAIN_ROUTES_CONST.customerJourney + "/" + projectId)
                        handleCReateProject()
                    }
                    else {
                        formik.setFieldError("billAmount", "Enter Bill Amount")
                        formik.setFieldTouched("billAmount", true)
                    }
                }}
            >
                <div className='pb-4'>
                    {USER_DETAILS_FIELDS.map((field: FormField) => (
                        <div key={`new-enquiry-form-${field.dataKey}`}>
                            <RenderFormComponents
                                formik={formik}
                                value={formik.values[field.dataKey as keyof object]}
                                {...field}
                                onChange={(e: any) => {
                                    formik.setFieldValue(field.dataKey, e)
                                }}
                            />
                        </div>
                    ))}
                </div>
            </CustomeDialog>
            <LoadingDialog isOpen={isLoading} message='wait....' />
        </div>
    )
}

const AreaCalculate = () => {
    return (
        <MainContextProvider>
            <Main />
        </MainContextProvider>
    )
}

export default AreaCalculate
