import { optionTypes } from "src/helpers";

export interface ContextProps {
    mapLocation: any;
    setMapLocation: (e: any) => void,
    setTotalSelectedArea: (e: any) => void,
    setTotalMapObstacleArea: (e: any) => void,
    setTotalMapPanelArea: (e: any) => void,
    totalSelectedArea: any;
    totalMapObstacleArea: any;
    totalMapPanelArea: any;
    totalObstaclesCount: any,
    settotalObstaclesCount: (e: any) => void,
    totalMapPanelCounts: any,
    setTotalMapPanelCounts: (e: any) => void,
    perMapPanelEnergy: any
    setMapPerPanelEnergy: (e: any) => void,
    setSubmitDetails : (e: any) => void,
    submitDetail : any;
    setQuotation: (quotationMethod: (() => void) | null) => void; // Set a callable method or null
    callQuotation: () => void; // Function to call the method
    projectId : any;
    setProId : (e: any) => void
}

export const initialContext = {
    mapLocation: undefined,
    totalSelectedArea: undefined,
    totalMapObstacleArea: undefined,
    totalMapPanelArea: undefined,
    totalMapPanelCounts: undefined,
    totalObstaclesCount: undefined,
    submitDetail : undefined,
    setMapLocation: () => { },
    setTotalSelectedArea: () => { },
    setTotalMapObstacleArea: () => { },
    setTotalMapPanelArea: () => { },
    settotalObstaclesCount: () => { },
    setTotalMapPanelCounts: () => { },
    perMapPanelEnergy: undefined,
    setMapPerPanelEnergy: () => { },
    setSubmitDetails : () =>{},
    setQuotation: () => {},
    callQuotation: () => {},
    projectId : undefined,
    setProId : () => {},

}
