import React from "react";
import { ISolarPlanArr, SolarPlanArr } from "./Component/modules";
import SolarplanCard from "./Component/SolarplanCard";
import { useSelector } from "react-redux";
import { getTempDataRootState } from "src/redux/reducers/tempDataSlice";
import { checkDecimal, COSTOMER_JOURNEY_SOLAR_IMAGE, SOLAR_PLANT_ICON, SOLAR_POWER_GENERATION_RUPEE_ICON } from "src/helpers";

const SolarPlan = ({ projectDetails }: { projectDetails: any }) => {

  // const { quotationDetails } = useSelector((state: any) => getTempDataRootState(state))

  return (
    <div className="font-sans mt-2">
      <div className='flex justify-center items-center space-x-2 text-primary font-bold text-xl md:text-2xl mt-4'><div>Plant Design And Price</div>
      </div>


      <div className=' md:w-[60vw] mx-auto my-4'>
        <div className='grid md:grid-cols-3 gap-4'>
          <div className="flex items-center space-x-4 border border-primary rounded-xl py-2 px-4">
            <img src={SOLAR_PLANT_ICON} alt="RuppeIcon" style={{ height: "40px" }} />
            <div>
              <div className="text-sm md:text-lg text-nowrap">Monthly Units Generation</div>
              <div className="text-sm md:text-lg font-bold">{projectDetails?.unitRequiredPerMonth ? checkDecimal((Number(projectDetails?.unitRequiredPerMonth).toString()), true) : 0} kW</div>
            </div>
          </div>
          <div className="flex items-center space-x-4 border border-primary rounded-xl py-2 px-4">
            <div>
              <div className="text-sm md:text-lg text-nowrap">Total Area Required</div>
              <div className="text-sm md:text-lg font-bold">{checkDecimal((projectDetails?.totalArea || 0), true) || 0} Sq.M.</div>
            </div>
          </div>
          <div className="flex items-center space-x-4 border border-primary rounded-xl py-2 px-4">
            <img src={SOLAR_POWER_GENERATION_RUPEE_ICON} alt="RuppeIcon" style={{ height: "40px" }} />
            <div>
              <div className="text-sm md:text-lg text-nowrap">Monthly Saving</div>
              <div className="text-sm md:text-lg font-bold">₹ {checkDecimal(projectDetails?.monthlyBillAmount, true) || 0}</div>
            </div>
          </div>
        </div>
      </div>

      <div className='flex justify-center items-center space-x-2 text-primary font-bold text-lg md:text-2xl mt-4'><div>Get the Best Solar Deals with Our Partners</div>
        <img src={COSTOMER_JOURNEY_SOLAR_IMAGE} alt='solarDeal' />
      </div>

      <div className=" flex justify-between">
        <div>
          <div className="text-[#000000B2] font-bold  md:text-[20px]">
            Select the Solar Plan that Powers your Growth.
          </div>
          <div className="md:text-[16px] font-normal text-[#191D2380]">
            Read all plans or contact us we will guide you better.
          </div>
        </div>
      </div>

      <div className="w-full grid grid-cols-1 md:grid-cols-3 mt-8 gap-12">
        {SolarPlanArr.map(
          (
            {
              benefits,
              emiPrice,
              name,
              overAllPrice,
              projectPrice,
              unlockPrice,
              features,
              typicalRate,
              interestSavings,
              id
            },
            index: number
          ) => {


            let per_kwh_price: any = process.env.REACT_APP_KW_PRICE_EC;
            let project_cost = ((projectDetails?.projectSize) * per_kwh_price).toFixed(2);
            let interest_rate = 10.99;
            let interest_amount = ((Number(project_cost) * interest_rate) / 100).toFixed(2);
            let totalProjectCost = Number(project_cost) + Number(interest_amount);
            let emi = interest_rate
            let years = "5"
            let efficiency = "Below 18%"
            let plantSize = projectDetails?.projectSize

            projectPrice = project_cost
            overAllPrice = totalProjectCost || 0
            emiPrice = interest_amount
            typicalRate = per_kwh_price
            id = projectDetails?._id


            if (name == "STANDARD") {
              per_kwh_price = process.env.REACT_APP_KW_PRICE_SD;
              project_cost = ((projectDetails?.projectSize) * per_kwh_price).toFixed(2);
              interest_rate = 10.99;
              interest_amount = ((Number(project_cost) * interest_rate) / 100).toFixed(2);
              totalProjectCost = Number(project_cost) + Number(interest_amount);
              projectPrice = project_cost
              overAllPrice = totalProjectCost || 0
              emiPrice = interest_amount
              typicalRate = per_kwh_price

            }
            else if (name == "premium") {
              per_kwh_price = process.env.REACT_APP_KW_PRICE_PR || 0;
              project_cost = ((projectDetails?.projectSize) * per_kwh_price).toFixed(2);
              interest_rate = 10.99;
              interest_amount = ((Number(project_cost) * interest_rate) / 100).toFixed(2);
              totalProjectCost = parseInt(project_cost) + parseInt(interest_amount);
              projectPrice = project_cost
              overAllPrice = totalProjectCost || 0
              emiPrice = interest_amount
              typicalRate = per_kwh_price
            }

            return (
              <SolarplanCard
                benefits={benefits}
                emiPrice={emiPrice}
                name={name}
                overAllPrice={overAllPrice}
                projectPrice={projectPrice}
                unlockPrice={unlockPrice}
                features={features}
                typicalRate={typicalRate}
                interestSavings={interestSavings}
                key={name}
                id={id}
                projectDetails={projectDetails}
                plantSize={plantSize}
              />
            )
          }
        )}
      </div>
    </div>
  );
};

export default SolarPlan;
