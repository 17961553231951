import { useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { toast } from 'sonner'
import { catchAsync, checkDecimal, IS_SUCCESS_RESPONSE, USER_ROLES } from 'src/helpers'
import { MAIN_ROUTES_CONST } from 'src/navigations/modules'
import { fetchCartItemsAction, getCartReducerRootState } from 'src/redux/reducers/cartReducer'
import { addOrderItem } from 'src/redux/reducers/orderReducer'
import { RootState } from 'src/redux/store'
import { addItemToCartApiRequest, updateCartItemsQuantityApiRequest } from 'src/services/requests/carts'
import QuantityCounter from 'src/shadcn/components/custom/QuantityCounter'
import { Button, ScrollArea } from 'src/shadcn/components/ui'
import { useAuth } from '../Auth/Core'


export interface EpcItemData {
  manufacturer_name: string;
  review: number;
  location: string;
  companyLogo: string;
  products: any;
  product_efficiency: number;
  noOfYearsExperiance: number;
  _id?: string
}


type Props = {
  d: any
}

const ListItem = (d: any) => {

  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [addingToCart, setAddingToCart] = useState<boolean>(false)
  const { cartItems } = useSelector((state: RootState) => getCartReducerRootState(state))
  const [isQuantityUpdating, setisQuantityUpdating] = useState<boolean>(false)
  const { currentUser } = useAuth()

  const handleAddItemToCart = catchAsync(async () => {
    setAddingToCart(true)
    return await addItemToCartApiRequest({
      "user_id": currentUser?.id,
      "product_id": d._id,
      "quantity": 1
    })
  }, (result: any) => {
    if (IS_SUCCESS_RESPONSE(result)) {
      toast.success("Product added into cart")
    }
    setAddingToCart(false)
  }, () => { setAddingToCart(false) })


  const handelUpdateProductQuantity = catchAsync(async (quantity: string) => {
    setisQuantityUpdating(true)
    return await updateCartItemsQuantityApiRequest(isProductInCartItems._id, {
      quantity: quantity
    })
  }, (result: any) => {
    if (IS_SUCCESS_RESPONSE(result)) {
      toast.success("Quantity Updated")
      dispatch(fetchCartItemsAction())
    }
    setisQuantityUpdating(false)
  }, () => { setisQuantityUpdating(false) })


  const isProductInCartItems: any = useMemo(() => {
    let checkItemInCart = cartItems.find((x: any) => x.product_id == d?._id)
    return checkItemInCart
  }, [d, cartItems])

  return (
    <div key={`${d?._id}`} className='flex flex-col space-y-2 rounded-lg border border-gray-300 hover:border-none hover:shadow-xl overflow-hidden transition duration-300'>
      <button onClick={() => navigate("/" + MAIN_ROUTES_CONST.products_details + "/" + d._id)} className='row-span-7 px-4 p-4'>
        <div className='flex flex-col space-y-1'>
          <div className=''>
            <img src={d?.productImage} className='rounded-xl' style={{ width: "100%", height: "180px" }} />
          </div>
          <div className='flex justify-center items-center h-100 w-100  text-center text-sm text-primary  font-semibold'>
            {d?.name}
          </div>
          <ScrollArea className='flex justify-center items-start h-100 w-100 h-[50px]  text-xs text-center '>
            {d?.description}

          </ScrollArea>
          <div className=' flex items-center justify-between'>
            <div className='flex flex-col  space-y-2 '>
              <span className='text-sm text-center text-gray-400'>Quantity</span>
              <span className='text-sm text-center text-primary font-semibold'># {checkDecimal(d.total_quantity, true)}</span>
            </div>
            <div className='flex flex-col  space-y-2'>
              <span className='text-sm text-center text-gray-400'>Price </span>
              <span className='text-sm text-center text-primary font-semibold'>₹ {checkDecimal(d.price, true)}/piece</span>
            </div>
          </div>
        </div>
      </button>
      { <div className='flex space-x-2 mt-4 px-4 pb-4'>
        {
          isProductInCartItems ? <>
            { <QuantityCounter isLoading={isQuantityUpdating} value={isProductInCartItems?.quantity} onChange={(e: any) => { handelUpdateProductQuantity(e) }} />}
          </> : <Button isLoading={addingToCart} onClick={() => { handleAddItemToCart() }} variant={'outlinePrimary'} className='flex-1' >Add To cart</Button>
        }
        <Button className='flex-1' onClick={() => {
          dispatch(addOrderItem({
            ...d,
            quantity: 1
          }))
          navigate("/" + MAIN_ROUTES_CONST.create_order)
        }} >Buy</Button>
      </div> }
    </div>
  )
}

export default ListItem