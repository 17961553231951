import React, { useEffect, useState } from "react";
import { useAuth } from "../Auth/Core";
import { catchAsync, IS_SUCCESS_RESPONSE } from "src/helpers";
import { getEnquiryHistoryApiRequest } from "src/services/requests/History";
import { Card } from "src/shadcn/components/ui";
import dayjs from "dayjs";
import ProjectResultsLoader from "../Marketplace/Loaders/ProjectResultsLoader";
import { DataTable } from "./Tabel/components/data-table";
import { columns } from "./Tabel/components/columns";

const EnquiryHistory = () => {
  const { currentUser } = useAuth();

  const [enquiryHistoryData, setEnquiryHistoryData] = useState<any>(null);
  const [isLoading, setisLoading] = useState<boolean>(false);

  useEffect(() => {
    if (currentUser?.id) {
      getAllWishlist();
    }
  }, []);

  const getAllWishlist = catchAsync(
    async () => {
      setisLoading(true);
      return (
        currentUser?.id &&
        (await getEnquiryHistoryApiRequest(currentUser?.id))
      );
    },
    (result: any) => {
      if (IS_SUCCESS_RESPONSE(result)) {
        setEnquiryHistoryData(result.data);
      }
      setisLoading(false);
    },
    () => {
      setisLoading(false);
    }
  );

  return (
    <div className="w-full px-4 h-full">
      {/* {isLoading ? (
        <div className="flex flex-col space-y-2">
          {[...new Array(3)].map((_, index) => (
            <ProjectResultsLoader key={index} />
          ))}
        </div>
      ) : (
        <>
          {enquiryHistoryData &&
          Array.isArray(enquiryHistoryData) &&
          enquiryHistoryData?.length > 0 ? (
            enquiryHistoryData.map((item: any) => (
              <Card
                key={item._id}
                className="w-full bg-white shadow-md rounded-lg p-4 border border-gray-200 space-y-2 translate-x-1 transform"
              >
                <h2 className="text-lg font-bold text-primary text-left">
                  {item.name || "Xyz"}
                </h2>
                <div className="grid grid-cols-1 space-y-4">
                
                  <div className="grid grid-cols-2 gap-4">
                    <div>
                      <div className="text-sm font-semibold text-primary">
                        Phone Number:
                      </div>
                      <p className="text-xs text-primary">
                        {item.phonenumber || "-"}
                      </p>
                    </div>
                    <div>
                      <div className="text-sm font-semibold text-primary">
                        Project Type :
                      </div>
                      <p className="text-xs text-primary">{item.project_type||"-"}</p>
                    </div>
                    <div>
                      <div className="text-sm font-semibold text-primary">
                        Type :
                      </div>
                      <p className="text-xs text-primary">{item.type||"-"}</p>
                    </div>
                  </div>

               
                  <div className="grid grid-cols-2 gap-4">
              
                    <div>
                      <div className="text-sm font-semibold text-primary">
                        User ID :
                      </div>
                      <p className="text-xs text-primary">{item.userid||"-"}</p>
                    </div>
                    <div>
                      <div className="text-sm font-semibold text-primary">
                        Created At :
                      </div>
                      <p className="text-xs text-primary">
                        {dayjs(new Date(item.createdAt)).format(
                          "DD-MM-YYYY HH:mm:ss A"
                        )}
                      </p>
                    </div>
                  </div>

              
                  <div className="">
                
                    <div>
                      <div className="text-sm font-bold text-primary">
                        Description :
                      </div>
                      <p className="text-xs text-primary">
                        {item.description ||
                          "-"}
                      </p>
                    </div>
                  </div>
                </div>
              </Card>
            ))
          ) : (
            <div className="text-primary">No data found</div>
          )}
        </>
      )} */}
<DataTable data={enquiryHistoryData || []} columns={columns} isLoading={isLoading}/>

    </div>
  );
};

export default EnquiryHistory;
