import React from 'react'
import SolarEpcServiceDetails from 'src/modules/SolarEPCService/SolarEpcServiceDetails'

type Props = {}

const SolarEPCService = (props: Props) => {
  return (
    <div>
      <SolarEpcServiceDetails />
    </div>
  )
}

export default SolarEPCService