import { useMemo } from "react";
import { BsBookmarkCheckFill } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import {
  convertToCamelCase,
  CUSTOMER_TYPE_ICON,
  ICON_OCT_VERIFIED,
  ICON_PROJECT_SIZE,
  PROJECT_CATEGORY_ICON,
  PROPERTY_TYPE_ICONS,
} from "src/helpers";
import { useAuth } from "src/modules/Auth/Core";
import { CELL_ROUTES } from "src/navigations/CellRoutes";
import { MAIN_ROUTES_CONST } from "src/navigations/modules";

type Props = {
  projects: Array<any>;
  loading: boolean;
};

type RenderItemProps = {
  projectName: string;
  _id: string;
  projectSize: string;
  customerType: string;
  projectCategoryType: string;
  projectType: string;
  systemType: string;
  totalProjectCost: string;
  returnPerYearRate: string;
  headerImage: any;
  termPlan: string;
  project_id: string;
  imagesAttachments: string[];
  requiredInvestment: string;
  totalInvestmentValue: string;
  buildingType: string;
  state: string;
  city: string;
};

const RenderItem = ({
  projectName,
  _id,
  projectSize,
  projectType,
  systemType,
  totalProjectCost,
  headerImage,
  returnPerYearRate,
  termPlan,
  project_id,
  imagesAttachments,
  requiredInvestment,
  totalInvestmentValue,
  buildingType,
  customerType,
  projectCategoryType,
  state,
  city,
}: RenderItemProps) => {
  const navigate = useNavigate();

  const { currentUser } = useAuth();

  const investmentPercentage = useMemo(() => {
    if (requiredInvestment && totalInvestmentValue) {
      console.log({ totalInvestmentValue, requiredInvestment });
      let newTotalInvestmentValue = parseFloat(totalInvestmentValue) || 0;
      let newRrequiredInvestment = parseFloat(requiredInvestment) || 0;
      console.log({ newTotalInvestmentValue, newRrequiredInvestment });

      let Percent = (newTotalInvestmentValue / newRrequiredInvestment) * 100;
      if (Percent <= 0) {
        Percent = 0;
      } else if (Percent <= 1) {
        Percent = 1;
      } else if (Percent >= 100) {
        Percent = 100;
      }
      return Percent.toFixed(0);
    }
    return 0;
  }, [totalInvestmentValue, requiredInvestment]);

  console.log({ totalInvestmentValue, requiredInvestment });

  return (
    <div
      onClick={() =>
        navigate(
          "/" + MAIN_ROUTES_CONST.cell + "/" + CELL_ROUTES.details + "/" + _id
        )
      }
      className="w-full md:w-100 md:min-h-[230px] min-h-[650px] grid md:grid-cols-4 gap-4 border border-2 border-gray-200 hover:shadow-md cursor-pointer rounded-2xl overflow-hidden transition duration-300 ease-in-out hover:scale-[1.01] "
    >
      <div className=" relative h-full" style={{ width: "100%" }}>
        <img
        //   style={{ width: "100%", height: "100%" }}
          src={imagesAttachments?.length > 0 ? imagesAttachments[0] : ""}
          className="w-full md:h-full h-[150px]"
        />
        <div className="absolute bottom-16 md:bottom-2 right-2">
          {" "}
          <img src={ICON_OCT_VERIFIED} style={{ height: "32px" }} />
        </div>
      </div>
      <div className="md:col-span-3 space-y-6 p-4">
        <div className="flex md:flex-row flex-col md:justify-between space-y-2 md:space-y-0">
          <div className="flex flex-col">
            <span className="font-bold uppercase text-primary">
              # {project_id}
            </span>
            <div className="flex space-x-2 items-center">
              {/* <span className='text-xs'># {project_id}</span> */}
              <span>
                <BsBookmarkCheckFill size={16} className="text-primary" />
              </span>
              <div className="flex items-center space-x-2">
                {Number(totalInvestmentValue) == 0 ? (
                  <span className="rounded-full bg-green-600 font-semibold text-[8px] py-[2px] px-[6px]  text-white uppercase ">
                    New
                  </span>
                ) : (
                  <span className="rounded-full bg-red-600 font-semibold text-[8px] py-[2px] px-[6px] text-white uppercase">
                    In-Progress
                  </span>
                )}
              </div>
            </div>
          </div>
          <div className="flex items-center space-x-2">
            <div className="text-xs flex space-x-2">
              <div>
                {city}, {state}
              </div>
            </div>
          </div>
        </div>

        <div className="grid md:grid-cols-4 grid-cols-2  md:gap-4 gap-x-4 gap-y-8 md:gap-y-0">
          <div className=" items-center space-y-1.5 px-4">
            <div className=" flex justify-center item-center">
              <img src={ICON_PROJECT_SIZE} style={{ height: "32px" }} />
            </div>
            <div className="text-xs text-center">Project Size</div>
            <div className=" font-bold text-sm text-center">
              {projectSize} kWp
            </div>
          </div>
          <div className=" items-center space-y-1.5 px-4">
            <div className=" flex justify-center item-center">
              <img
                src={PROPERTY_TYPE_ICONS[projectType as keyof object]}
                style={{ height: "32px" }}
              />
            </div>
            <div className="text-xs text-center">Project Type </div>
            <div className=" font-bold text-sm text-center">
              {" "}
              {convertToCamelCase(projectType?.toLocaleLowerCase())}
            </div>
          </div>
          <div className=" items-center space-y-1.5 px-4">
            <div className=" flex justify-center item-center">
              <img
                src={CUSTOMER_TYPE_ICON[customerType as keyof object]}
                style={{ height: "32px" }}
              />
            </div>
            <div className="text-xs text-center">Project Category </div>
            <div className="font-bold text-sm text-center ">
              {" "}
              {customerType}
            </div>
          </div>
          <div className=" items-center space-y-1.5 px-4">
            <div className=" flex justify-center item-center">
              <img
                src={PROJECT_CATEGORY_ICON[projectCategoryType as keyof object]}
                style={{ height: "32px" }}
              />
            </div>
            <div className="text-xs text-center">Service Type </div>
            <div className=" font-bold text-sm text-center">
              {" "}
              {projectCategoryType}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const ResultFilter = ({ projects, loading }: Props) => {
  console.log(projects, "my projectsssssssssssssssssssssss");

  return (
    <div className="flex flex-col space-y-2">
      {projects?.length > 0 &&
        projects?.map((project: any) => {
          return <RenderItem {...project} />;
        })}
    </div>
  );
};

export default ResultFilter;
