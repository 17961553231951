import { Button } from "src/shadcn/components/ui/button";
import {
  cn,
  FormField,
  HERO_IMG_1,
  ICON_ENERGY_SUN,
  RESIDENTIAL_BANNER,
} from "src/helpers";
import {
  Carousel,
  CarouselApi,
  CarouselContent,
  CarouselItem,
} from "src/shadcn/components/ui/carousel";
import { ReactSVG } from "react-svg";
import RoundedButton from "src/shadcn/components/custom/RoundedButton";
import { useNavigate } from "react-router-dom";
import { MAIN_ROUTES_CONST } from "src/navigations/modules";
import { useState } from "react";
import { useCusrousal } from "./useCusrousal";
import CustomeDialog from "src/shadcn/components/custom/CustomeDialog";
import {
  CUSTOMER_DETAILS_FIELDS,
  CUSTOMER_DETAILS_INIT,
  CustomerDetailsFormSchema,
} from "../ReStructure/Services/Component/modules";
import RenderFormComponents from "src/shadcn/components/custom/RenderFormComponents";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "src/redux/store";
import {
  getTempDataRootState,
  setResidentialUserDetails,
} from "src/redux/reducers/tempDataSlice";

type Props = {};

const HeroSection = (props: Props) => {
  const navigation = useNavigate();
  const [openUserDetailsDailog, setOpenUserDetailsDailog] = useState(false);
  const [setApi, setSetApi] = useState<CarouselApi>();
  const dispatch = useDispatch();
  const { residentialUserDetails } = useSelector((state: RootState) =>
    getTempDataRootState(state)
  );

  const { selectedIndex, scrollSnaps, onDotButtonClick } = useCusrousal(setApi);

  const formik = useFormik({
    initialValues: CUSTOMER_DETAILS_INIT,
    validationSchema: CustomerDetailsFormSchema,
    onSubmit(values, formikHelpers) {
      dispatch(
        setResidentialUserDetails({
          ...residentialUserDetails,
          ...values,
        })
      );
      navigation("/" + MAIN_ROUTES_CONST.area_calculator);
    },
  });

  return (
    <div className="relative">
      {/* Carousel Section */}
      <Carousel setApi={setSetApi} className="">
        <CarouselContent>
          {/* Slide 1 */}
          <CarouselItem>
            <div
              className={cn(`w-full h-[60vh] md:h-[80vh] flex items-center`)}
              style={{
                background: `url(${RESIDENTIAL_BANNER})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            >
              <div className="w-full px-4 md:px-20 lg:px-32 space-y-3 text-center md:text-left">
                <div className="pt-[10vh] md:pt-[15vh] lg:pt-[20vh]">
                  <h1 className="font-sans font-bold text-2xl md:text-3xl lg:text-5xl leading-tight">
                    Harness the Power of the Sun, Lower Your Bills!
                  </h1>
                  <p className="text-sm md:text-lg lg:text-xl font-normal mt-3">
                    Take control of your energy consumption and save on
                    electricity bills every month
                  </p>
                  <Button
                    onClick={() => setOpenUserDetailsDailog(true)}
                    className="mt-6 md:mt-10"
                    size={"lg"}
                  >
                    Design Your Rooftop
                  </Button>
                </div>
              </div>
            </div>
          </CarouselItem>
        </CarouselContent>
      </Carousel>

      {/* Pagination Dots */}
      <div className="absolute bottom-8 left-0 right-0 flex justify-center space-x-2">
        {scrollSnaps.map((span: any, indx: number) => {
          return (
            <button
              key={`dot-${indx}`}
              className={cn("h-1 w-6 md:w-10 bg-gray-300 rounded-full", {
                "bg-primary": selectedIndex == indx,
              })}
              onClick={() => onDotButtonClick(indx)}
            ></button>
          );
        })}
      </div>

      {/* Form Dialog */}
      <form>
        <CustomeDialog
          isOpen={openUserDetailsDailog}
          headerTitle=""
          className="max-w-xs md:max-w-md lg:max-w-lg"
          onCancle={() => setOpenUserDetailsDailog(false)}
          onSubmit={() => formik.handleSubmit()}
        >
          <div className=" mb-8 ">
            {CUSTOMER_DETAILS_FIELDS.map((field: FormField) => {
              return (
                <div key={`new-enquiry-form-${field.dataKey}`} >
                  <RenderFormComponents
                    formik={formik}
                    value={formik.values[field.dataKey as keyof object]}
                    {...field}
                    onChange={(e: any) =>
                      formik.setFieldValue(field.dataKey, e)
                    }
                  />
                </div>
              );
            })}
          </div>
        </CustomeDialog>
      </form>
    </div>
  );
};

export default HeroSection;
