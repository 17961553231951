import { ADMIN_BASE_URL, BASE_URL, MOBILE_BASE_URL } from "../api_const"

export const PROJECTS_BASE_URL = BASE_URL + "/projects"

export const GET_ALL_PROJECTS = PROJECTS_BASE_URL
export const GET_PROJECT_DETAILS = BASE_URL + "/projects"

export const CREATE_NEW_PROJECT_REQUEST = MOBILE_BASE_URL + "/projects/enquiry"
export const CREATE_QUOTATION_PROJECT_REQUEST = BASE_URL + "/user/create/projects"
export const FETCH_QUOTATION_PROJECT_REQUEST = BASE_URL + "/user/get/projects"
export const CREATE_EXISTING_PROJECT_REQUEST = BASE_URL + "/offtaker/existing/project/enquiry"
export const UPDATE_PROJECT_DETAILS_REQUEST = BASE_URL + "/user/update/projects"
export const UPDATE_MAP_DETAILS_REQUEST = "https://ba.energymarketplace.in/api/v1/solar-map"
export const PROJECT_CALCULATION_UPDATE =  "/project/calcaultion/update"
