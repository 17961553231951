import { FormField, NUMBER_REGEX } from "src/helpers";
import CustomTextIcon from "src/shadcn/components/custom/CustomeSymbols/CustomTextIcon";
import * as Yup from "yup" 

export interface IUserDetailsForm {
    billAmount: string,
}

export const USER_DETAILS_FIELDS: FormField[] = [
    {
        label: '',
        dataKey: 'billAmount',
        componentType: 'input',
        componentProps: {
            type: 'number',
            required: true,
            numberFormatted:true,
            placeholder: 'Amount ',
            inputPrefixIcon:<CustomTextIcon value="₹" />
        },
    },

];

export const USER_DETAILS_INIT: IUserDetailsForm = {
    billAmount: '',
};

export const USERDetailsFormSchema = Yup.object().shape({
    billAmount: Yup.string().required('bill amount is required'),
});