import { Minus, Plus, TriangleAlert } from "lucide-react";
import React, { useState } from "react";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
  Button,
  Collapsible,
  Separator,
} from "src/shadcn/components/ui";
import PlusMinus from "./PlusMinus";

const SolarPlanAccordian = () => {
  const [accordianVal, setAccordianVal] = useState("");
  return (
    <div className="w-full mt-6 font-sans">
      <Accordion
        type="single"
        collapsible
        className="w-full space-y-2"
        onValueChange={(value) => setAccordianVal(value)}
        // defaultValue={apiData && apiData[0]?._id}
      >
        <AccordionItem
          value={"1"}
          className="border border-[#0000000D] rounded-lg"
        >
          <AccordionTrigger className="w-full hover:no-underline flex justify-between px-6 items-center bg-[#EDEDED]">
            <div className="text-primary text-lg font-bold flex justify-center items-center space-x-4">
              <div>Disclaimers</div>
              <TriangleAlert className="text-primary" strokeWidth={3} />
            </div>
            {/* <PlusMinus accordianVal={accordianVal} value={`1`} /> */}
          </AccordionTrigger>
          <AccordionContent className="w-full text-xs text-grayLable font-medium px-6">
            <div className="grid grid-cols-2 mt-4">
              <div className="space-y-1">
                <div className="flex items-center space-x-2">
                  <div className="w-[12px] h-[13px] bg-primary"></div>
                  <div className="text-lg font-bold text-black">
                    Price Includes
                  </div>
                </div>
                <div className="text-[#454545] font-normal space-x-2 flex items-baseline ml-4">
                  <ul className="list-disc pl-6">
                    <li>
                      {" "}
                      Detailed design, all material, Electreecity App,
                      installation <br /> ( transport and taxes ) 1 year AMC
                      free.
                    </li>
                  </ul>
                </div>
              </div>
              <div className="space-y-1">
                <div className="flex items-center space-x-2">
                  <div className="w-[12px] h-[13px] bg-primary"></div>
                  <div className="text-lg font-bold text-black">
                    Net Marketing
                  </div>
                </div>

                <ul className="list-disc pl-6 text-[#454545] font-normal">
                  <li>
                    {" "}
                    Electreecity” platform offers net metering process for
                    customers. Utility <br /> and facilitation charges extra.
                  </li>
                  <li>
                    (Typically ~ ₹ 10,000 - ₹ 25,000 depending on state and
                    connection
                    <br /> type).
                  </li>
                </ul>
              </div>
            </div>
          </AccordionContent>
        </AccordionItem>

     

        <AccordionItem
          value={"3"}
          className="border border-[#0000000D] rounded-lg"
        >
          <AccordionTrigger className="w-full hover:no-underline flex justify-between px-6 items-center bg-[#EDEDED]">
            <div className="text-primary text-lg font-bold flex justify-center items-center space-x-4">
              <div>Terms & Conditions</div>
            </div>
            {/* <PlusMinus accordianVal={accordianVal} value={`3`} /> */}
          </AccordionTrigger>
          <AccordionContent className="w-full text-xs text-grayLable font-medium px-6">
            <div className=" mt-4 space-y-4">
              <ul className="list-disc pl-6 text-[#454545] font-normal space-y-2">
                <li>
                  Applicable only for<strong>Resident Indian customers.</strong>
                </li>
                <li>
                  Applicable only for<strong>Resident Indian customers.</strong>
                </li>
                <li>
                  {" "}
                  For Commercial and Industrial connections, Business owner can
                  avail this financing option on their own name by providing
                  required documents subject to above{" "}
                  <strong>conditions .</strong>
                </li>
                <li>
                  {" "}
                  Customers need to <strong>pay 20% as down payment</strong> to
                  Oct.
                </li>
                <li>
                  {" "}
                  Remaining <strong>80% amount can be paid</strong> in easy EMIs
                  as per above chart.
                </li>
              </ul>
            </div>
          </AccordionContent>
        </AccordionItem>

        <AccordionItem
          value={"4"}
          className="border border-[#0000000D] rounded-lg"
        >
          <AccordionTrigger className="w-full hover:no-underline flex justify-between px-6 items-center bg-[#EDEDED]">
            <div className="text-primary text-lg font-bold flex justify-center items-center space-x-4">
              <div>Loan Terms</div>
            </div>
            {/* <PlusMinus accordianVal={accordianVal} value={`4`} /> */}
          </AccordionTrigger>
          <AccordionContent className="w-full text-xs text-grayLable font-medium px-6">
            <div className=" mt-4 space-y-4">
              <div className="font-bold text-lg">
                What are the terms and conditions for solar loans
              </div>

              <div className="text-[#454545]">
                <div className="font-bold text-sm text-primary">
                  For Individuals{" "}
                </div>
                <div className="text-sm font-normal">
                  <b>Low-cost EMI Loan Details</b>
                  <br />
                  25% Upfront payment
                  <br />
                  75% amount in 6-24 months EMIs
                </div>

                <div className="text-sm font-normal mt-4">
                  <b>Interest Rate</b>
                  <br />
                  General 0-12%
                </div>
              </div>
              <Separator
                className="w-full border-dashed border-[1px]"
                orientation="horizontal"
              />

              <div className="text-[#454545]">
                <div className="font-bold text-sm text-primary">
                  For Commercial Sites, Factories, colleges or hospitals
                </div>
                <div className="text-sm font-normal">
                  <b>Loan Amount Limits</b>
                  <br />
                  Up to ₹ 1.5 Crore or 80% of project cost ( whichever is lower
                  )
                </div>

                <div className="text-sm font-normal mt-4">
                  <b>Interest Rate</b>
                  <br />
                  General 10.65 %
                </div>
              </div>
              <Separator
                className="w-full border-dashed border-[1px]"
                orientation="horizontal"
              />

              <div className="text-[#454545]">
                <div className="font-bold text-sm text-primary">
                  For Housing Societies
                </div>
                <div className="text-sm font-normal">
                  <b>Loan Amount Limits</b>
                  Up to ₹ 1.5 Crore or 80% of project cost ( whichever is lower
                  )
                </div>

                <div className="text-sm font-normal mt-4">
                  <b>Interest Rate</b>
                  <br />
                  General 10.65%
                </div>
              </div>
              <Separator
                className="w-full border-dashed border-[1px]"
                orientation="horizontal"
              />

              <div className="text-[#454545]">
                <div className="font-bold text-sm text-primary">
                  For All categories
                </div>
                <div className="text-sm font-normal">
                  <b>Loan duration</b>
                  <br />
                  Up to 7 years
                </div>

                <div className="text-sm font-normal mt-4">
                  <b>Loan processing time needed</b> <br />
                  Typically 3-4 years
                </div>

                <div className="text-sm font-normal mt-4">
                  <b>Loan processing charges</b> <br />
                  Up to ₹ 1 Lakh Free!
                  <br />
                  Above ₹ 1 Lakh From 0.50% of loan amount.
                </div>
              </div>
              <Separator
                className="w-full border-dashed border-[1px]"
                orientation="horizontal"
              />

              <div className="text-[#454545]">
                <div className="font-bold text-sm text-primary">
                  Insurance Requirements
                </div>
                <div className="text-sm font-normal">
                  <b>Loan Amount Limits</b>
                  <br />
                  Solar assets created with such bank loans need to be insured
                  in favour of the bank for the duration of the loan
                </div>
              </div>

              <div className="w-full flex justify-center items-center mt-4">
                <Button className="w-[235px] h-[43px] text-center rounded-full">
                  I am Interested
                </Button>
              </div>
            </div>
          </AccordionContent>
        </AccordionItem>

        <AccordionItem
          value={"5"}
          className="border border-[#0000000D] rounded-lg"
        >
          <AccordionTrigger className="w-full hover:no-underline flex justify-between px-6 items-center bg-[#EDEDED]">
            <div className="text-primary text-lg font-bold flex justify-center items-center space-x-4">
              <div>Warranty</div>
            </div>
            <PlusMinus accordianVal={accordianVal} value={`5`} />
          </AccordionTrigger>
          <AccordionContent className="w-full text-xs text-[#454545] font-medium px-6">
            <div className="p-6">
              <h2 className="text-2xl font-semibold mb-4">
                What are the warranties on the system?
              </h2>

              <div className="mb-6">
                <h3 className="text-xl font-semibold text-primary">
                  Solar Panels:
                </h3>
                <ul className="list-disc pl-6 space-y-1">
                  <li>
                    10 year manufacturing defect warranty by manufacturer.
                  </li>
                  <li>25 year performance warranty</li>
                  <li>
                    Less than 1% annual degradation in line with international
                    standards.
                  </li>
                  <li>MNRE and International Standards certified.</li>
                </ul>
              </div>

              <div className="mb-6">
                <h3 className="text-xl font-semibold text-primary">
                  Inverter:
                </h3>
                <ul className="list-disc pl-6 space-y-1">
                  <li>
                    10 year manufacturing defect warranty by manufacturer.
                  </li>
                  <li>25 year performance warranty</li>
                  <li>
                    Less than 1% annual degradation in line with international
                    standards.
                  </li>
                  <li>MNRE and International Standards certified.</li>
                </ul>
              </div>

              <div className="mb-6">
                <h3 className="text-xl font-semibold text-primary">
                  Mounting Structure:
                </h3>
                <ul className="list-disc pl-6 space-y-1">
                  <li>
                    80-micron Galvanized Iron or Aluminum. Designed for 15 kmph
                    wind speeds and more than 5 years of structural integrity.
                  </li>
                </ul>
              </div>

              <div className="mb-6">
                <h3 className="text-xl font-semibold text-primary">
                  Free 1 Year maintenance is included with all Oct Systems:
                </h3>
                <ul className="list-disc pl-6 space-y-1">
                  <li>
                    Free data for Oct monitoring device, with information
                    displayed on the Oct App.
                  </li>
                  <li>
                    Contact Orange Current support directly from the Oct app
                    itself.
                  </li>
                  <li>
                    Alerts and notifications about system maintenance on the
                    app.
                  </li>
                  <li>Onsite troubleshooting.</li>
                  <li>
                    Replacement of any part of the system, caused by design or
                    structural.
                  </li>
                  <li>
                    <strong>Excluded:</strong> Routine washing and cleaning of
                    panels is to be done by the customer.
                  </li>
                </ul>
              </div>
            </div>
          </AccordionContent>
        </AccordionItem>
      </Accordion>
    </div>
  );
};

export default SolarPlanAccordian;
