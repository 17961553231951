import { useState } from "react";
import CustomeDialog from "src/shadcn/components/custom/CustomeDialog";
import { Card } from "src/shadcn/components/ui";
import ContactusForm from "../ContactUs";
import { IoCloseCircle } from "react-icons/io5";
import { IContactUdForm } from "../ContactUs/modukes";


const ServicePlan = () => {
  const [openContactUsDailog, setOpenContactUsDailog] = useState(false);

  const CONTACT_US_INIT: IContactUdForm = {
    name: '',
    phoneNumber: '',
    email: '',
    queryType: 'Loan Request',
    description: '',
    attachments: undefined,
  };

  return (
    <div className="font-sans mt-12  sm:px-8 md:px-16 " >
      {/* Heading */}
      <div className="text-primary font-bold text-xl sm:text-2xl md:text-3xl text-center">
        Select the Service Plan.
      </div>
      <div className="text-sm sm:text-[14px] md:text-[16px] font-normal text-gray-700 text-center mt-2">
        Read all plans or contact us, we will guide you better.
      </div>

      {/* Cards Container */}
      <div className="flex flex-wrap justify-center items-center gap-6 md:gap-8 mt-8">
        {/* ECOFY Card */}
        <Card onClick={() => { setOpenContactUsDailog(true) }} className="font-sans border hover:bg-[#E0995833]  border-primary border-t-[5px] rounded-[20px] sm:rounded-[26px] w-full sm:w-[400px] md:w-[413px] drop-shadow-lg p-4 sm:p-6 md:p-9 space-y-4">
          <h1 className="text-lg sm:text-xl md:text-2xl font-bold text-primary text-center uppercase">
            ECOFY
          </h1>
          <div className="text-[#231D4F] flex justify-between sm:justify-center sm:space-x-3 text-sm sm:text-lg md:text-xl">
            <div className="font-normal">Rate Of Interest :</div>
            <div className="font-bold">7.99% to 9.99%</div>
          </div>
          <div className="text-[#231D4F] flex justify-between sm:justify-center sm:space-x-3 text-sm sm:text-lg md:text-xl">
            <div className="font-normal">Processing Fee:</div>
            <div className="font-bold">2%</div>
          </div>
          <div className="text-[#231D4F] flex justify-between sm:justify-center sm:space-x-3 text-sm sm:text-lg md:text-xl">
            <div className="font-normal">Repayment Term :</div>
            <div className="font-bold">3 to 7 Years</div>
          </div>
          <div className="text-[#231D4F] flex justify-between sm:justify-center sm:space-x-3 text-sm sm:text-lg md:text-xl">
            <div className="font-normal">Type of Credit :</div>
            <div className="font-bold">Instalment credit</div>
          </div>
          <div className="text-[#231D4F] flex justify-between sm:justify-center sm:space-x-3 text-sm sm:text-lg md:text-xl">
            <div className="font-normal">Closing Cost :</div>
            <div className="font-bold">2% - 5%</div>
          </div>
        </Card>

        {/* CREDIT FAIR Card */}
        <Card onClick={() => { setOpenContactUsDailog(true) }} className="font-sans hover:bg-[#E0995833] border  border-primary border-t-[5px] rounded-[20px] sm:rounded-[26px] w-full sm:w-[400px] md:w-[413px] drop-shadow-lg p-4 sm:p-6 md:p-9 space-y-4">

          <h1 className="text-lg sm:text-xl md:text-2xl font-bold text-primary text-center uppercase">
            CREDIT FAIR
          </h1>
          <div className="text-[#231D4F] flex justify-between sm:justify-center sm:space-x-3 text-sm sm:text-lg md:text-xl">
            <div className="font-normal">Rate Of Interest :</div>
            <div className="font-bold">8.5% to 9.99%</div>
          </div>
          <div className="text-[#231D4F] flex justify-between sm:justify-center sm:space-x-3 text-sm sm:text-lg md:text-xl">
            <div className="font-normal">Processing Fee:</div>
            <div className="font-bold">2%</div>
          </div>
          <div className="text-[#231D4F] flex justify-between sm:justify-center sm:space-x-3 text-sm sm:text-lg md:text-xl">
            <div className="font-normal">Repayment Term :</div>
            <div className="font-bold">3 to 7 Years</div>
          </div>
          <div className="text-[#231D4F] flex justify-between sm:justify-center sm:space-x-3 text-sm sm:text-lg md:text-xl">
            <div className="font-normal">Type of Credit :</div>
            <div className="font-bold">Revolving credit</div>
          </div>
          <div className="text-[#231D4F] flex justify-between sm:justify-center sm:space-x-3 text-sm sm:text-lg md:text-xl">
            <div className="font-normal">Closing Cost :</div>
            <div className="font-bold">Vary, often lower</div>
          </div>
        </Card>
      </div>

      <CustomeDialog
        isOpen={openContactUsDailog}
        headerTitle=""
        hideDefaultSubmit
        hideDefaultCancle
        className="max-w-xs md:max-w-md lg:max-w-lg"
        onCancle={() => setOpenContactUsDailog(false)}

      >
        <div>
          <IoCloseCircle onClick={() => setOpenContactUsDailog(false)} size={24} className='text-gray-400 absolute right-1 top-3' />
          <ContactusForm setOpenContactUsDailog={setOpenContactUsDailog} initialValue={CONTACT_US_INIT}/>
        </div>
      </CustomeDialog>
    </div>
  );
};

export default ServicePlan;
