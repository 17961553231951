import React from 'react'
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogHeader,
    DialogTitle
} from "src/shadcn/components/ui";
import { Loader2 } from 'lucide-react';

type Props = {
    isOpen: boolean;
    onCancelClick?: () => void;
    message?: string;
};

const LoadingDialog = ({ isOpen, onCancelClick, message }: Props) => {
    return (
        <Dialog open={isOpen} onOpenChange={() => { }}>
            <DialogContent hideClose>
                <DialogHeader>
                    <DialogTitle></DialogTitle>
                    <DialogDescription>
                        <div className="flex flex-col items-center">
                            <div className="flex space-x-2 items-center ">
                                <div>please wait</div>
                                <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                            </div>
                            <div>
                                Do not close dialog and reload page until complete.
                            </div>
                        </div>
                    </DialogDescription>
                </DialogHeader>
            </DialogContent>
        </Dialog>

    )
}

export default LoadingDialog